import axios from 'axios';
import config from '../../config';

export const fetchViewsSuccess = (views) => {
    return {
        type: 'FETCH_VIEWS_SUCCESS',
        payload: views
    };
};

export const fetchViews= () => {
    return (dispatch) => {
        axios.get(config.apiBaseUrl + "views/all")
            .then((response) => {
                dispatch(fetchViewsSuccess(response.data.returnValue));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const fetchAllParents = () => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "views/all-parents";
        const response = await axios.get(url);
        if (response.data.success) {
            const parents = response.data.returnValue;
            dispatch({ type: 'FETCH_ALLPARENTS_SUCCESS', payload: parents });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('fetchAllParents failed:', error);
        return false;
    }
};


export const fetchAnalytics = () => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "analytics";
        const response = await axios.get(url);
        if (response.data.success) {
            const analytics = response.data.returnValue;
            dispatch({ type: 'ANALYTICS_SUCCESS', payload: analytics });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('fetchAnalytics failed:', error);
        return false;
    }
};

export const createViews = (newDossierData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "views/create";
        const response = await axios.post(url, newDossierData);
        if (response.data.success) {
            const createdViews= response.data.returnValue;
            dispatch({ type: 'CREATE_VIEWS_SUCCESS', payload: createdViews });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('createViews failed:', error);
        return false;
    }
};
    
export const updateViews = (id, updatedDossiersData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "views/update";
        const response = await axios.post(url, updatedDossiersData);
        if (response.data.success) {
            const url = config.apiBaseUrl + "views/all";
            const response = await axios.get(url);
            let updatedViews = response.data.returnValue;
            dispatch({ type: 'UPDATE_VIEWS_SUCCESS', payload: { updatedViews } });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('updateViews failed:', error);
        return false;
    }
};


export const deleteViews = (id) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "views/" + id;
        const response = await axios.delete(url);
        if (response.data.success) {
            dispatch({ type: 'DELETE_VIEWS_SUCCESS', payload: id });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('deleteViews failed:', error);
        return false;
    }
};
