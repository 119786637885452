const initialState = {
  newsletter: [],
  loading: true,
};
export const newsletterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_NEWSLETTER_SUCCESS':
      return {
        ...state,
        newsletter: action.payload,
        loading: false,
      };
    
    case 'DELETE_NEWSLETTER_SUCCESS':
      const updatedNewsletterItems = state.newsletter.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        newsletter: updatedNewsletterItems,
      };
  
    default:
      return state;
  }
};