/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import config from '../../config';
import { connect } from 'react-redux';


function AboutPage(props) {
    const { user, isAuthenticated } = props;
    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div
                        id="profile-profile"
                        className="col-md-8"
                    >
                        <div className="iq-card ">
                            <div className="iq-card-body ">
                                <div className="user-detail text-center">
                                    <div className="user-profile">
                                        <img
                                            src="/images/logo.jpg"
                                            alt="profile-img"
                                            className="avatar-130 img-fluid"
                                        />
                                    </div>
                                    <div className="profile-detail mt-3">
                                        <h5 className="d-inline-block text-muted">{user.nom} - {user.email}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="iq-card ">
                            <div className="iq-card-header d-flex justify-content-between ">
                                <div className="iq-header-title">
                                    <h4 className="card-title text-primary">A Propos</h4>
                                </div>
                                <div className="iq-card-header-toolbar d-flex align-items-center" />
                            </div>
                            <div className="iq-card-body ">
                                <div className="user-bio">
                                    <p>Nouveau récépissé</p>
                                    <p><strong>No 3489/CAO/TGI/PF du 06/09/2016</strong></p>
                                    <p>Ancien récépissé</p>
                                    <p>N° 808/04/GA-GI/OUA/P.F. du 2 avril 2004</p>
                                    <p><span role="img" aria-label="directeur">👤</span> Directeur de publication : Cyriaque PARE</p>
                                    <p>Lefaso.net est publié par les Editions Lefaso, un groupe de communication en ligne.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Informations Supplémentaires</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <ul className="list-inline p-0 m-0">
                                    <li className="d-flex mb-3 align-items-center p-3 sell-list border-danger rounded">
                                        <div className="media-support-info ml-3">
                                            <h6>Numéro du Bot</h6>
                                        </div>
                                        <div className="iq-card-header-toolbar d-flex align-items-center">
                                            <div className="badge badge-pill badge-info">{config.appNumber}</div>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center p-3 sell-list border-primary rounded">
                                        <div className="media-support-info ml-3">
                                            <h6>Email</h6>
                                        </div>
                                        <div className="iq-card-header-toolbar d-flex align-items-center">
                                            <div className="badge badge-pill badge-primary">{config.email}</div>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center p-3 sell-list border-success rounded">
                                        <div className="media-support-info ml-3">
                                            <h6>ID WhatApp Business</h6>
                                        </div>
                                        <div className="iq-card-header-toolbar d-flex align-items-center">
                                            <div className="badge badge-pill badge-success">{config.wBAccountID}</div>
                                        </div>
                                    </li>
                                    <li className="d-flex mb-3 align-items-center p-3 sell-list border-danger rounded">
                                        <div className="media-support-info ml-3">
                                            <h6>Adresse</h6>
                                        </div>
                                        <div className="iq-card-header-toolbar d-flex align-items-center">
                                            <div className="badge badge-pill badge-danger">{config.address}</div>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-center p-3 sell-list border-info rounded">
                                        <div className="media-support-info ml-3">
                                            <h6>Site Web</h6>
                                        </div>
                                        <div className="iq-card-header-toolbar d-flex align-items-center">
                                            <div className="badge badge-pill badge-info">{config.website}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(AboutPage);