/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchNews, createNews, updateNews, deleteNews, } from '../../services/actions/newsActions';
import { fetchCategories } from '../../services/actions/categoriesActions';
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

function NewsPage(props) {

    const { loading, news, fetchNews, categories, fetchCategories } = props;
    const [expanded, setExpanded] = useState([]);
    const maxDescriptionLength = 400;
    const dispatch = useDispatch();
    const [formErrors, setFormErrors] = useState({});
    const [editState, setEditState] = useState(false);
    const [formloading, setFormLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showCardList, setShowCardList] = useState(true);
    const { user, isAuthenticated } = props;

    const toggleDescription = (index) => {
        const newExpandedState = [...expanded];
        newExpandedState[index] = !newExpandedState[index];
        setExpanded(newExpandedState);
    };

    function showCreateForm() {
        setShowForm(true);
        setShowCardList(false);
        setEditState(false);
    }

    function hideCreateForm() {
        setShowForm(false);
        setShowCardList(true);
        setFormData({});
        setFormErrors({});
        setEditState(false);
    }

    const [formData, setFormData] = useState({
        titre: '',
        category_id:'',
        image: '',
        lien: '',
        description: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.titre || !data.description) {
            errors.global = 'Veuillez remplir les champs';
        } else {
            if (!data.titre.trim()) {
                errors.titre = 'Le titre est requis';
            }
            if (!data.description.trim()) {
                errors.description = 'La description est requise';
            }
        }

        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log({ formData })
        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({});
        setFormLoading(true);
        if (editState) {
            const success = await dispatch(updateNews(formData));
            if (success) {
                dispatch(showSuccessMessage('Mise à jour effectuée avec succès!'));
                hideCreateForm();
            } else {
                dispatch(showErrorMessage('Une erreur s\'est produite lors de la mise à jour.'));
            }
        } else {
            formData.user_id = user.id;
            console.log({ formData })
            const success = await dispatch(createNews(formData));
            if (success) {
                dispatch(showSuccessMessage('Élément ajouté avec succès!'));
                hideCreateForm();
            } else {
                dispatch(showErrorMessage('Une erreur s\'est produite lors de l\'ajout.'));
            }
        }
        setFormLoading(false);
    };

    function showEditForm(actu) {
        setEditState(true);
        formData.slug = actu.slug;
        formData.titre = actu.titre;
        formData.category_id = actu.category_id;
        formData.image = actu.image;
        formData.lien = actu.lien;
        formData.description = actu.description;
        showCreateForm();
    }

    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deleteNews(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                }
            }
        });
    };

    const showItem = (item) => {
        const content = `
        <div class="card card-height bg-light-gray">
                <div class="card-body">
                 <h5 class="card-title text-center text-bold">${item.titre}</h5>
                    <span class="badge badge-pill iq-bg-success my-2 text-dark">Catégorie: ${item.category ? item.category.titre : '-'}</span>
                    <span class="mx-2 badge badge-pill iq-bg-dark my-2 text-dark">Date:${item.created_at.slice(0, 10)}</span>
                    <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                       <a href=${item.lien} target="_blank" > Lien vers le site</a>
                    </span>
                    <p class="card-text text-justify">${item.description}</p>
                </div>
            </div>`;
        Swal.fire({
            title: item.title,
            html: content,
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: false,
        });
    };


    useEffect(() => {
        fetchNews();
        fetchCategories()
    }, [fetchNews, fetchCategories]);

    return (
        <div id="content-page" className="content-page">
             <div className="container-fluid">
                 {loading ? (
                     <div className="card-body">
                         <Spinner />
                     </div>
                 ) : 
                    <div className="row">
                        {showForm && (
                            <div className="col-sm-12 col-lg-12">
                                <div className="iq-card ">
                                    <div className="iq-card-header d-flex justify-content-between ">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Ajouter</h4>
                                            {formErrors.global && (
                                                <div className="invalid-feedback">{formErrors.global}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="iq-card-body ">
                                        <form className="">
                                            <div role="group" className="form-group">
                                                <label
                                                    htmlFor="title"
                                                    className="d-block"

                                                >
                                                    Titre
                                                </label>
                                                <div>
                                                    <input
                                                        id="titre"
                                                        type="text"
                                                        name="titre"
                                                        className={`form-control ${formErrors.titre ? 'is-invalid' : ''}`}
                                                        value={formData.titre}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {formErrors.titre && (
                                                        <div className="invalid-feedback">{formErrors.titre}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label
                                                    htmlFor="category"
                                                    className="d-block"
                                                >
                                                    Catégorie
                                                </label>
                                                <div>
                                                    <select
                                                        id="category_id"
                                                        name="category_id"
                                                        className={`form-control ${formErrors.category ? 'is-invalid' : ''
                                                            }`}
                                                        value={formData.category_id}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">Sélectionnez une catégorie</option>
                                                        {categories.map((category) => (
                                                            <option key={category.id} value={category.id}>
                                                                {category.titre}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label htmlFor="image" className="d-block">
                                                    Lien Image:
                                                </label>
                                                <div>
                                                    <input
                                                        id="image"
                                                        type="link"
                                                        name="image"
                                                        className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}
                                                        value={formData.image}
                                                        onChange={handleInputChange}

                                                    />

                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label htmlFor="lien" className="d-block" >
                                                    Lien sur le site:
                                                </label>
                                                <div>
                                                    <input
                                                        id="lien"
                                                        type="link"
                                                        name="lien"
                                                        className={`form-control ${formErrors.lien ? 'is-invalid' : ''}`}
                                                        value={formData.lien}
                                                        onChange={handleInputChange}
                                                    />

                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label
                                                    htmlFor="exampleFormControlTextarea1"
                                                    className="d-block"
                                                >
                                                    Contenu
                                                </label>
                                                <div>
                                                    <textarea
                                                        id="description"
                                                        name="description"
                                                        rows={5}
                                                        wrap="soft"
                                                        className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}
                                                        value={formData.description}
                                                        onChange={handleInputChange}
                                                    />
                                                    {formErrors.description && (
                                                        <div className="invalid-feedback">{formErrors.description}</div>
                                                    )}
                                                </div>
                                            </div>


                                            <button type="submit" className="btn btn-success" onClick={handleSubmit} disabled={formloading}>
                                                {formloading ? 'Envoi en cours...' : 'Envoyer'}
                                            </button>
                                            <button onClick={() => hideCreateForm()} className="btn iq-bg-danger ml-3 btn-none">
                                                Annuler
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                         <div className="col-lg-12">
                             <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                 <div className="iq-card-header d-flex justify-content-between">
                                     <div className="iq-header-title">
                                        <h4 className="card-title text-primary">Toutes Les Actualités</h4>
                                     </div>
                                     <div className="iq-card-header-toolbar d-flex align-items-center">

                                        <button className="btn btn-info" onClick={() => showCreateForm()}>
                                             <i className="ri-add-circle-line" />
                                             Ajouter
                                         </button>
                                     </div>
                                 </div>
                                 <div className="iq-card-body row">
                                     {!loading && news.length === 0 ?
                                         (
                                             <div>
                                                 <h3> Aucune Actualité n'a été trouvée.</h3>
                                             </div>
                                         ) : news.map((singleItem, index) => (
                                             <div key={singleItem.id} className="col-lg-6 col-sm-12 ">
                                                 <div className="card card-height actus">
                                                     <img src={singleItem.image} alt="#" className="card-img-top imageProper" />
                                                     <div className="card-body">
                                                         <h4 className="card-title">{singleItem.titre.length > 100 ? singleItem.titre.slice(0, 100) + '...' : singleItem.titre}</h4>
                                                         <span class="badge badge-pill iq-bg-success my-2 text-dark">Catégorie:{singleItem.category ? singleItem.category.titre : '-'}</span>
                                                         <span class="mx-2 badge badge-pill iq-bg-dark my-2 text-dark">Date:{singleItem.created_at.slice(0, 10)}</span>
                                                         <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                                                             <i className="ri-share-line mr-1" />
                                                             <a href={singleItem.lien} target="_blank" > Lien vers le site</a>
                                                         </span>
                                                         <p className={`card-text ${expanded[index] ? 'expanded' : ''}`}>
                                                             {singleItem.description.slice(0, maxDescriptionLength) + '...'}
                                                             {/* {expanded[index]
                                                                 ? singleItem.description
                                                                 : singleItem.description.slice(0, maxDescriptionLength)}
                                                                {singleItem.description.length > maxDescriptionLength && (
                                                                 <span>
                                                                     {!expanded[index] && '... '}
                                                                     <a
                                                                         href="#"
                                                                         className="card-link"
                                                                         onClick={() => toggleDescription(index)}
                                                                     >
                                                                         {expanded[index] ? ' Lire moins' : 'Lire plus'}
                                                                     </a>
                                                                 </span>
                                                             )} */}

                                                         </p>
                                                     </div>
                                                     <div className="card-footer bg-light-gray">
                                                         <div className=' text-center'>
                                                             <button type="button" class="btn btn-info ml-0" onClick={() => showItem(singleItem)}>
                                                                 <i class="ri-eye-line font-size-16"></i>
                                                                 Consulter
                                                             </button>
                                                             <button type="button" class="btn btn-warning ml-3" onClick={() => showEditForm(singleItem)}>
                                                                 <i class="ri-pencil-fill font-size-16"></i>
                                                                 Modifier
                                                             </button>
                                                             <button type="button" class="btn btn-danger ml-3" onClick={() => showDeleteConfirmation(singleItem.id)}>
                                                                 <i class="ri-delete-bin-line font-size-16"></i>
                                                                 Supprimer
                                                             </button>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                     ))}
                                 </div>
                             </div>
                         </div>
                     </div>
                 }                
             </div>
         </div>
        
    );
}

const mapStateToProps = (state) => {
    return {
        news: state.news.news,
        categories: state.categories.categories,
        loading: state.news.loading,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNews: () => dispatch(fetchNews()),
        fetchCategories: () => dispatch(fetchCategories())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);