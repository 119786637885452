/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import config from '../../config';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchNewsLetter, deleteNewsLetter } from '../../services/actions/newsletterActions';
import Spinner from '../../components/Spinner';
import Swal from 'sweetalert2';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';
// import json2csv from 'json2csv';
import CsvDownloadButton from 'react-json-to-csv';


function NewsletterPage(props) {
    const { loading, newsletter, fetchNewsLetter } = props;
    const dispatch = useDispatch();

    // const generateCsvContent = () => {
    //     if (newsletter.length > 0) {
    //         const fields = ['nom', 'email', 'mailingList', 'created_at']; // Define the fields you want in the CSV
    //         const csvData = newsletter.map((subscription) => ({
    //             nom: subscription.nom,
    //             email: subscription.email,
    //             mailingList: subscription.mailingList,
    //             created_at: subscription.created_at,
    //         }));

    //         const csv = json2csv.parse(csvData, { fields });
    //         downloadCsvFile(csv);
    //     }
    // };

    // const downloadCsvFile = (csvContent) => {
    //     const blob = new Blob([csvContent], { type: 'text/csv' });
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'newsletter-data.csv'; // Specify the CSV file name
    //     document.body.appendChild(a);
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    // };



    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deleteNewsLetter(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                    // Swal.fire('Supprimé!', "Suppression avec Succès", 'success');
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                    //Swal.fire('Erreur', "Une erreur s'est produite lors de la suppression de l'élément.", 'error');
                }
            }
        });
    };

    useEffect(() => {
        fetchNewsLetter();
    }, [fetchNewsLetter]);

     return (
         <div id="content-page" className="content-page">
             <div className="container-fluid">
                 {loading ? (
                     <div className="card-body">
                         <Spinner />
                     </div>
                 ) : 
                     <div className="row">
                         <div className="col-lg-12">
                             <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                 <div className="iq-card-header d-flex justify-content-between">
                                     <div className="iq-header-title">
                                         <h4 className="card-title text-primary">Abonnés de La Newsletter</h4>
                                     </div>
                                     <div className="iq-card-header-toolbar d-flex align-items-center">
        
                                         {/* <button onClick={generateCsvContent}  className="btn btn-info">
                                             <i className="ri-download-2-line" />
                                             Exporter
                                         </button> */}

                                         <CsvDownloadButton
                                             data={newsletter}
                                             filename="newsletter.csv"
                                             className="btn btn-info"
                                         >
                                             <i className="ri-download-2-line" />
                                             Exporter
                                         </CsvDownloadButton>
                                     </div>
                                 </div>
                                 <div className="iq-card-body ">
                                     <div className="mb-0 table-responsive">
                                         <table
                                             role="table"
                                             aria-busy="false"
                                             aria-colcount={6}
                                             className="table b-table table-borderless"
                                             id="__BVID__485"
                                         >
                                             <thead role="rowgroup" className="">
                                                 <tr role="row" className="">
                                                     <th
                                                         role="columnheader"
                                                         scope="col"
                                                         aria-colindex={1}
                                                         className=""
                                                     >
                                                         <div>#</div>
                                                     </th>
                                                     <th
                                                         role="columnheader"
                                                         scope="col"
                                                         aria-colindex={2}
                                                         className=""
                                                     >
                                                         <div>Nom</div>
                                                     </th>
                                                     <th
                                                         role="columnheader"
                                                         scope="col"
                                                         aria-colindex={3}
                                                         className=""
                                                     >
                                                         <div>Email</div>
                                                     </th>
                                                     {/* <th
                                                     role="columnheader"
                                                     scope="col"
                                                     aria-colindex={4}
                                                     className=""
                                                 >
                                                     <div>Choix</div>
                                                 </th> */}
                                                     <th
                                                         role="columnheader"
                                                         scope="col"
                                                         aria-colindex={5}
                                                         className=""
                                                     >
                                                         <div>Mailing List</div>
                                                     </th>
                                                     <th
                                                         role="columnheader"
                                                         scope="col"
                                                         aria-colindex={6}
                                                         className=""
                                                     >
                                                         <div>Date</div>
                                                     </th>
                                                     <th
                                                         role="columnheader"
                                                         scope="col"
                                                         aria-colindex={6}
                                                         className="text-center"
                                                     >
                                                         <div>Actions</div>
                                                     </th>
                                                 </tr>
                                             </thead>
                                             <tbody role="rowgroup">
                                                 {!loading && newsletter.length === 0 ?
                                                     (
                                                         <tr className="text-center my-3">
                                                             <td colSpan="8">
                                                                 Aucun abonné n'a été trouvé.
                                                             </td>
                                                         </tr>
                                                     ) : (newsletter.map((subscription, index) => (
                                                         <tr role="row" className="">
                                                             <td aria-colindex={1} role="cell" className="">
                                                                 {++index}
                                                             </td>
                                                             <td aria-colindex={2} role="cell" className="">
                                                                 {subscription.nom}
                                                             </td>
                                                             <td aria-colindex={3} role="cell" className="">
                                                                 {subscription.email}
                                                             </td>
                                                             {/* <td aria-colindex={4} role="cell" className="">
                                                         <span className={`badge badge-${subscription.action.toLowerCase() === 'inscription' ? 'info' : 'danger'} badge-pill`}>
                                                             {subscription.action}
                                                         </span>
                                                     </td> */}
                                                             <td aria-colindex={5} role="cell" className="">
                                                                 <span className={`badge badge-${subscription.mailingList.toLowerCase() === 'annonce' ? 'secondary' : 'primary'} badge-pill`}>
                                                                     {subscription.mailingList}</span>
                                                             </td>
                                                             <td aria-colindex={6} role="cell" className="">
                                                                 {subscription.created_at.slice(0, 10)}
                                                             </td>
                                                             <td aria-colindex={7} role="cell" className="">
                                                                 <div class="d-flex align-items-center justify-content-center">
                                                                     {/* <a class="avatar-45 text-center ml-3 rounded-circle iq-bg-success font-size-18"><i class="ri-pencil-fill font-size-16"></i></a> */}
                                                                     <a onClick={() => showDeleteConfirmation(subscription.id)} class="avatar-45 rounded-circle text-center ml-3 iq-bg-danger font-size-18"><i class="ri-delete-bin-line font-size-16"></i></a>
                                                                 </div>
                                                             </td>
                                                         </tr>
                                                     )))
                                                     }
                                             </tbody>
                                         </table>
                                     </div>
                                 </div>
                                 {/**/}
                             </div>
                         </div>
                     </div>
                 }                
             </div>
         </div>
     );
}

const mapStateToProps = (state) => {
    return {
        newsletter: state.newsletter.newsletter,
        loading: state.newsletter.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNewsLetter: () => dispatch(fetchNewsLetter())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterPage);