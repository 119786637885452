import axios from 'axios';
import config from '../../config';

export const fetchUsersSuccess = (users) => {
    return {
        type: 'FETCH_USERS_SUCCESS',
        payload: users
    };
};

export const fetchUsers = () => {
    return (dispatch) => {
        axios.get(config.apiBaseUrl + "users/all")
            .then((response) => {
                dispatch(fetchUsersSuccess(response.data.returnValue));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};
export const createUser = (newUserData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "auth/register";
        const response = await axios.post(url, newUserData);
        if (response.data.success) {
            const createdUser = response.data.returnValue;
            dispatch({ type: 'CREATE_USER_SUCCESS', payload: createdUser });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('createUser failed:', error);
        return false;
    }
};

export const updateUser = (id, updatedUserData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "users/update";
        console.log({ updatedUserData })
        const response = await axios.post(url, updatedUserData);
        if (response.data.success) {
            const url = config.apiBaseUrl + "users/all";
            const response = await axios.get(url);
            let updatedUsers = response.data.returnValue;
            dispatch({ type: 'UPDATE_USER_SUCCESS', payload: { updatedUsers } });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('updateUser failed:', error);
        return false;
    }
};


export const deleteUser = (id) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "users/" + id;
        const response = await axios.delete(url);
        if (response.data.success) {
            dispatch({ type: 'DELETE_USER_SUCCESS', payload: id });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('deleteUser failed:', error);
        return false;
    }
};
