/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';
import { toastr } from 'react-redux-toastr';
import { login } from '../../services/actions/authActions';

export default function LoginPage(props) {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


    const dispatch = useDispatch();

    const handleSuccess = (msg) => {
        dispatch(showSuccessMessage(msg ||'This is a success message!'));
    };

    const handleError = (msg) => {
        dispatch(showErrorMessage(msg || 'This is an error message!'));
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleLogin = async (e) => {
        if (rememberMe) {
            // If "Remember Me" is checked, store the email and password
            localStorage.setItem('rememberedEmail', email);
            localStorage.setItem('rememberedPassword', password);
        } else {
            // If not checked, clear any previously stored credentials
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
        }
        e.preventDefault();

        // Show loader
        setLoading(true);

        if (!email) {
            setEmailError('Veuillez saisir votre adresse e-mail.');
            setLoading(false); // Hide loader
        } else if (!email.match(emailRegex)) {
            setEmailError('Veuillez saisir une adresse e-mail valide.');
            setLoading(false); // Hide loader
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('Veuillez saisir votre mot de passe.');
            setLoading(false); // Hide loader

        } else {
            setPasswordError('');
        }

        if (email && password && email.match(emailRegex)) {
            try {
                  await dispatch(login(email, password));
                   sessionStorage.setItem('isAuthenticated', true);
                //navigate('/bot/dashboard');
                let url = '/bot/dashboard';
                window.location.href = url;
            }catch (error) {
                handleError('Identifiants Incorrects');
            }
            // if (email === 'admin@fasonet.com' && password === 'fasonet') {
            //    
            //     sessionStorage.setItem('isAuthenticated', true);
            // } else {
            //     handleError('Identifiants Incorrects');
            // }
        }

        setLoading(false); // Hide loader after the login process
    };

    useEffect(() => {
        const rememberedEmail = localStorage.getItem('rememberedEmail');
        const rememberedPassword = localStorage.getItem('rememberedPassword');

        if (rememberedEmail && rememberedPassword) {
            setEmail(rememberedEmail);
            setPassword(rememberedPassword);
            setRememberMe(true); // Check the "Remember Me" checkbox
        }
    }, []);

    return (
        <div>
            <section className="sign-in-page">
                <div className="container loginFormArea mt-5 p-0">
                    <div className="row no-gutters">
                        <div className="col-sm-6 align-self-center">
                            {/* <div className="col-sm-12">
                                <div
                                    role="alert"
                                    aria-live="polite"
                                    aria-atomic="true"
                                    className="alert alert-primary"
                                >

                                    <div className="iq-alert-text">
                                        <h5 className="alert-heading">Bienvenue sur l'Admin de LeFasonetBot!</h5>
                                        <p>
                                            Gérez votre chatbot WhatsAp
                                        </p>

                                    </div>
                                </div>
                            </div> */}

                            <div className="sign-in-from">

                                <h3 className="mb-4 dark-signin">Espace de Connexion à FasoNetBot</h3>
                                {/* <p>Veuillez renseigner votre adresse e-mail et votre mot de passe pour accéder au panneau d'administration.</p> */}
                                <form onSubmit={handleLogin}>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Email</label>
                                        <input
                                            type="email"
                                            className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                            name="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={e => {
                                                setEmail(e.target.value);
                                                setEmailError('');
                                            }}
                                        />
                                        {emailError && <div className="invalid-feedback">{emailError}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Mot De Passe</label>
                                        {/* <a href="/auth/recover-password" className="float-right">Mot De Passe Oublié ?</a> */}
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className={`form-control ${passwordError ? 'is-invalid' : ''}`}
                                            name="password"
                                            placeholder="Mot De Passe"
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value);
                                                setPasswordError('');
                                            }}
                                        />
                                        {showPassword ? (
                                            <span>
                                                <i className="passwordViewer ri-eye-off-line" onClick={togglePasswordVisibility}  />
                                            </span>
                                        ) : (
                                                <span>
                                                    <i className="passwordViewer ri-eye-line" onClick={togglePasswordVisibility} />
                                                </span>
                                            
                                        )}
                                        {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            <input type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} /> Se Souvenir de Moi
                                        </label>
                                    </div>

                                    <div className="d-inline-block w-100">
                                        {/* <button type="submit" className="btn btn-primary float-right" onClick={handleLogin}>Connexion</button> */}
                                        <button type="submit" className="btn btn-primary btn-lg " disabled={loading}>
                                            {loading ? 'Chargement...' : 'Connexion'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-6 text-center">
                            <div className="sign-in-detail text-white">
                                <div className="item image-container">
                                    <img src="/images/login.png" className="img-fluid mb-4 centered-image" alt="logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}