/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect,useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchAnnonces, createAnnonces, updateAnnonces, deleteAnnonces } from '../../services/actions/petitesannoncesActions';
import Spinner from '../../components/Spinner';
import Swal from 'sweetalert2';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';


function PetitesAnnonces(props) {
    const { loading, annonces, fetchAnnonces } = props;
    const [expanded, setExpanded] = useState([]);
    const maxDescriptionLength = 400;

    const toggleDescription = (index) => {
        const newExpandedState = [...expanded];
        newExpandedState[index] = !newExpandedState[index];
        setExpanded(newExpandedState);
    };

    const dispatch = useDispatch();

    function showCreateModal() {
        Swal.fire({
            title: 'Ajouter',
            html: '<form>' +
                '<div class="form-group">' +
                '<label for="titre">Titre de l\'annonce</label>' +
                '<input id="titre" class="swal2-input col-md-6" placeholder="Titre de l\'annonce" value="" required>' +
                '</div>' +
                '<div class="form-group">' +
                '<label for="image">Lien de l\'image</label>' +
                '<input id="image" class="swal2-input col-md-6" placeholder="Lien de l\'image" value="" required>' +
                '</div>' +
                '<div class="form-group">' +
                '<label for="lien">Lien vers le site</label>' +
                '<input id="lien" class="swal2-input col-md-6" placeholder="Lien vers le site" value="" required>' +
                '</div>' +
                '<div class="form-group">' +
                '<label for="description">Description </label>' +
                '<textarea id="description" class="mr-1 swal2-textarea col-md-6" placeholder="Description de l\'annonce" rows="5" required></textarea>' +
                '</div>' +
                '</form>',
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Envoyer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
                cancelButton: 'btn btn-danger bg-danger',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            preConfirm: async () => {
                const titre = Swal.getPopup().querySelector('#titre').value;
                const image = Swal.getPopup().querySelector('#image').value;
                const lien = Swal.getPopup().querySelector('#lien').value;
                const description = Swal.getPopup().querySelector('#description').value;

                if (!titre || !image || !lien || !description) {
                    Swal.showValidationMessage('Veuillez remplir tous les champs.');
                } else {
                    let createData = {
                        titre,
                        image,
                        lien,
                        description
                    };
                    const success = await dispatch(createAnnonces(createData));
                    if (success) {
                        dispatch(showSuccessMessage('Elément ajouté avec succès!'));
                    } else {
                        dispatch(showErrorMessage("Une erreur s'est produite lors de la création de l'élément."));
                    }
                }
            },
        });
    }


    function showEditModal(annonce) {
        Swal.fire({
            title: 'Modifier',
            html:
                '<form><div className="form-group"><label htmlFor="titre">Titre de l\'annonce</label>' +
                '<input id="titreEdit" class="swal2-input col-md-6" placeholder="Titre de l\'annonce" value="' + annonce.titre + '"></div>' +
                ' <div className="form-group"><label htmlFor="lien">Lien de l\'image</label>' +
                '<input id="imageEdit" class="swal2-input col-md-6" placeholder="Lien de l\'image" value="' + annonce.image + '"></div></form>' +
                '<div className="form-group"><label htmlFor="lien">Lien vers le site</label>' +
                '<input id="lienEdit" class="swal2-input col-md-6" placeholder="Lien vers le site" value="' + annonce.lien + '">' +
                '<div className="form-group"><label for="description">Description </label>' + '<textarea id="descriptionEdit" class="mr-1 swal2-textarea col-md-6" placeholder="Description de l\'annonce" rows="10" required>' + annonce.description +'</textarea>' +
                '</div></form > ',
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Envoyer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
                cancelButton: 'btn btn-danger bg-danger',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            preConfirm: async () => {
                const updatedTitle = Swal.getPopup().querySelector('#titreEdit').value;
                const updatedLink = Swal.getPopup().querySelector('#imageEdit').value;
                const updatedLien = Swal.getPopup().querySelector('#lienEdit').value;
                const updatedDescription = Swal.getPopup().querySelector('#descriptionEdit').value;
                let updateData = {
                    id: annonce.id,
                    titre: updatedTitle,
                    image: updatedLink,
                    lien: updatedLien,
                    description: updatedDescription
                }
                const success = await dispatch(updateAnnonces(annonce.id, updateData));
                if (success)
                    dispatch(showSuccessMessage('Mise à jour effectuée avec succès!'));
                else
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la mise à jour de l'élément."));
            },
        });
    }

    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deleteAnnonces(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                   
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                    
                }
            }
        });
    };

    useEffect(() => {
        fetchAnnonces();
    }, [fetchAnnonces]);

     return (
         <div id="content-page" className="content-page">
             <div className="container-fluid">
                 {loading ? (
                     <div className="card-body">
                         <Spinner />
                     </div>
                 ) : 
                     <div className="row">
                         <div className="col-lg-12">
                             <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                 <div className="iq-card-header d-flex justify-content-between">
                                     <div className="iq-header-title">
                                         <h4 className="card-title text-primary">Liste des Petites Annonces</h4>
                                     </div>
                                     <div className="iq-card-header-toolbar d-flex align-items-center">

                                         <button className="btn btn-info" onClick={() => showCreateModal()}>
                                             <i className="ri-add-circle-line" />
                                             Ajouter
                                         </button>
                                     </div>
                                 </div>
                                 <div className="iq-card-body row">
                                     {!loading && annonces.length === 0 ?
                                         (
                                             <div>
                                                 <h3> Aucune Petite Annonce n'a été trouvé.</h3>
                                             </div>
                                         ) : annonces.map((annonce, index) => (
                                             <div key={annonce.id} className="col-lg-6 col-sm-12 ">
                                                 <div className="card vraiOuFaux actus">
                                                     <img src={annonce.image} alt="#" className="card-img-top imageProper" />
                                                     <div className="card-body">
                                                         <h4 className="card-title">{annonce.titre.length > 100 ? annonce.titre.slice(0, 100) + '...' : annonce.titre}</h4>
                                                         <div className='mt-2'>
                                                             <span class="badge badge-pill iq-bg-warning my-2 text-dark">
                                                                 <i className="ri-calendar-line mr-1" />
                                                                 {annonce.created_at.slice(0, 10)}
                                                             </span>
                                                             <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                                                                 <i className="ri-share-line mr-1" />
                                                                 <a href={annonce.lien} target="_blank" > Lien vers le site</a>
                                                             </span>
                                                         </div>
                                                         <p className={`card-text ${expanded[index] ? 'expanded' : ''}`}>
                                                             {/* {expanded[index]
                                                                 ? annonce.description
                                                                 : annonce.description}
                                                             {annonce.description.length > maxDescriptionLength && (
                                                                 <span>
                                                                     {!expanded[index] && '... '}
                                                                     <a
                                                                         href="#"
                                                                         className="card-link"
                                                                         onClick={() => toggleDescription(index)}
                                                                     >
                                                                         {expanded[index] ? ' Lire moins' : 'Lire plus'}
                                                                     </a>
                                                                 </span>
                                                             )} */}
                                                         </p>
                                                     </div>
                                                     <div className="card-footer bg-light-gray">
                                                         {/* Lien au site: <a href="#" className="card-link">{annonce.lien}</a> */}
                                                         <div className="mt-3 text-center">
                                                             <button
                                                                 type="button"
                                                                 className="btn btn-warning ml-0"
                                                                 onClick={() =>
                                                                     showEditModal(
                                                                         annonce
                                                                     )
                                                                 }
                                                             >
                                                                 <i className="ri-pencil-fill font-size-16"></i>
                                                                 Modifier
                                                             </button>
                                                             <button
                                                                 type="button"
                                                                 className="btn btn-danger ml-3"
                                                                 onClick={() => showDeleteConfirmation(annonce.id)}
                                                             >
                                                                 <i className="ri-delete-bin-line font-size-16"></i>
                                                                 Supprimer
                                                             </button>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                     ))}
                                 </div>
                             </div>
                         </div>
                     </div>
                 }                
             </div>
         </div>
     );
}

const mapStateToProps = (state) => {
    return {
        annonces: state.annonces.annonces,
        loading: state.annonces.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAnnonces: () => dispatch(fetchAnnonces())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PetitesAnnonces);