import React, { useState } from "react";
import { connect } from 'react-redux';


const Header = (props) => {
    const { isAuthenticated, user } = props;


    const getCurrentDate = () => {
        const options = {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
        };
        return new Date().toLocaleDateString("fr-FR", options);
    };


    return (
        <div className="iq-top-navbar">
            <div className="iq-navbar-custom bg-primary">
                <nav className="navbar navbar-expand-lg navbar-light p-0">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-3 navbar-list">
                            <li className="nav-item float-left">
                                <h5 className="text-white">Nous sommes le {getCurrentDate()}</h5>
                            </li>
                        </ul>
                    </div>
                    <ul className="navbar-list">
                        <li className="bg-white">
                            <a href="#" className="search-toggle iq-waves-effect d-flex align-items-center">
                                <img src="/images/user.png" className="img-fluid rounded mr-3" alt="user" />
                                <div className="caption">
                                    {isAuthenticated ? (
                                        <>
                                            <h6 className="mb-0 line-height">{user.nom}</h6>
                                            <span className="font-size-12">{user.email}</span>
                                        </>
                                    ) : (
                                        <>
                                            <h6 className="mb-0 line-height">Guest</h6>
                                            <span className="font-size-12">Non connecté</span>
                                        </>
                                    )}
                                </div>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Header);
