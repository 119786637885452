const initialState = {
    views: [],
    parents:[],
    analytics:[],
    loading: true,
};

export const viewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_VIEWS_SUCCESS':
            return {
                ...state,
                views: action.payload,
                loading: false,
            };
        
        case 'FETCH_ALLPARENTS_SUCCESS':
            return {
                ...state,
                parents: action.payload,
                loading: false,
            };
        
        case 'CREATE_VIEWS_SUCCESS':
            const createdViews = action.payload;
            return {
                ...state,
                views: [createdViews, ...state.views],
            };
        
        case 'ANALYTICS_SUCCESS':
            return {
                ...state,
                analytics: action.payload,
                loading: false,
        };
        
        case 'UPDATE_VIEWS_SUCCESS':
            const { updatedViews } = action.payload;
            return {
                ...state,
                views: updatedViews,
            };

        case 'DELETE_VIEWS_SUCCESS':
            let newViews = state.views.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                views: newViews,
            };
        
        default:
            return state;
    }
};
