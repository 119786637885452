/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ScrollToTop from './ScrollToTop.js';

export default function Footer(props) {

    return (
        <footer className="iq-footer bg-primary">
            <div className="container-fluid">
                <div className="row">
                    < ScrollToTop />
                    <div className="col-lg-12 text-center ">
                        Copyright 2023.  Tous droits réservés.
                    </div>
                </div>
            </div>
        </footer>
        
    );
}