import axios from 'axios';
import config from '../../config';

export const fetchVraiFauxSuccess = (vraifaux) => {
  return {
    type: 'FETCH_VRAIFAUX_SUCCESS',
    payload: vraifaux
  };
};

export const fetchVraiFaux = () => {
  return (dispatch) => {
    axios.get(config.apiBaseUrl + "vrai-ou-faux/all")
      .then((response) => {
        dispatch(fetchVraiFauxSuccess(response.data.returnValue));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const createVraiFaux = (newVraiFauxData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "vrai-ou-faux/create";
    const response = await axios.post(url, newVraiFauxData);
    if (response.data.success) {
      const createdVraiFaux= response.data.returnValue;
      dispatch({ type: 'CREATE_VRAIFAUX_SUCCESS', payload: createdVraiFaux });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('createVraiFaux failed:', error);
    return false;
  }
};

export const updateVraiFaux = (id, updatedVraiFauxData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "vrai-ou-faux/update";
    const response = await axios.post(url, updatedVraiFauxData);
    if (response.data.success) {
      const url = config.apiBaseUrl + "vrai-ou-faux/all";
      const response = await axios.get(url);
      let newVraiFaux = response.data.returnValue;
      dispatch({ type: 'UPDATE_VRAIFAUX_SUCCESS', payload: { newVraiFaux } });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('updateVraiFaux failed:', error);
    return false;
  }
};

export const deleteVraiFaux = (id) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "vrai-ou-faux/" + id;
    const response = await axios.delete(url);
    if (response.data.success) {
      dispatch({ type: 'DELETE_VRAIFAUX_SUCCESS', payload: id });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('deleteVraiFaux failed:', error);
    return false;
  }
};