/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomFieldId, getNewSubscribers } from '../../services/actions/contactsActions';

function ContactsPage(props) {

    const dispatch = useDispatch();
    const subscribers = useSelector((state) => state.contacts.subscribers);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const id = await dispatch(getCustomFieldId());
            if (id) {
                dispatch(getNewSubscribers(id));
            }
        };
        fetchData();
    }, [dispatch]);

    const filteredSubscribers = subscribers.filter((subscriber) =>
        subscriber.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title text-primary">Tous Les Contacts</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className="col-sm-12 col-md-12">
                                    <div id="user_list_datatable_info" className="dataTables_filter">
                                            <div className="form-group mb-0">
                                            <input
                                                type="search"
                                                className="form-control"
                                                id="exampleInputSearch"
                                                placeholder="Rechercher"
                                                aria-controls="user-list-table"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                            </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table id="user-list-table" className="table table-striped table-borderless mt-4" role="grid" aria-describedby="user-list-page-info">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Nom & Prénom</th>
                                                <th>WhatsApp</th>
                                                <th>Statut</th>
                                                <th>Souscription</th>
                                                {/* <th>Action</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredSubscribers.map((subscriber) => (
                                                <tr>
                                                    <td className="text-center">
                                                        <div className="d-flex">
                                                            <span className="avatar-45 text-center ml-3 rounded-circle iq-bg-success font-size-18">{subscriber.name.charAt(0).toUpperCase()}</span>
                                                        </div>
                                                    </td>
                                                    <td>{subscriber.name}</td>
                                                    <td>{subscriber.whatsapp_phone}</td>
                                                    <td><span class="badge bg-primary iq-bg- text-white">{subscriber.status}</span></td>
                                                    <td>{subscriber.subscribed.slice(0, 10)}</td>
                                                    {/* <td>
                                                        <div class="flex align-items-center list-user-action">
                                                            <a class="iq-bg-info" href="#"><i class="ri-pencil-line"></i></a>
                                                            <a class="iq-bg-danger" href="#"><i class="ri-delete-bin-line"></i></a>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactsPage;