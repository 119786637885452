/* eslint-disable import/no-anonymous-default-export */
export default {
    appName: "FasoNetBot",
    appNumber: "+22667326167",
    email: "contact@lefaso.net",
    wBAccountID: 149431798246804,
    address: "Ouagadougou, Burkina Faso",
    website: "https://lefaso.net",
    pageDescription: "Lefaso.net est un média en ligne généraliste du Burkina Faso, fondé en 1999 par Cyriaque Paré.",
    botDescription: "Je suis Lefaso.netBot 🤖, votre guide au cœur du flux d'actualités !",

    //botToken: "706108:ef7c639005223d0305f123953245ae99",
    botToken: "791756:f9495e21c9363e7f0f6ccd6ce168613a",
    botBaseUrl: "https://api.manychat.com/fb/",

    apiBaseUrl: "https://api-fasonet.warilo.com/api/admin/"
};