/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchNews, createNews, updateNews, deleteNews, } from '../../services/actions/newsActions';
import { fetchCategories } from '../../services/actions/categoriesActions';
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

function CategoriesPage(props) {

    const { loading, news, fetchNews, categories, fetchCategories } = props;
    const [expanded, setExpanded] = useState([]);
    const maxDescriptionLength = 400;
    const dispatch = useDispatch();

    const toggleDescription = (index) => {
        const newExpandedState = [...expanded];
        newExpandedState[index] = !newExpandedState[index];
        setExpanded(newExpandedState);
    };

    function showCreateModal() {
        Swal.fire({
            title: 'Ajouter',
            html:
                '<form><div className="form-group"><label htmlFor="titre">Titre de l\'article</label>' +
                '<input id="titre" class="swal2-input col-md-6" placeholder="Titre de l\'article" value=""></div>' +
                ' <div className="form-group"><label htmlFor="lien">Lien de l\'image</label>' +
                '<input id="image" class="swal2-input col-md-6" placeholder="Lien de l\'image" value="">' + '<div class="form-group">' +
                '<div class="form-group">' +
                '<label for="lien">Lien vers le site</label>' +
                '<input id="lien" class="swal2-input col-md-6" placeholder="Lien vers le site" value="" required>' +
                '</div>' +
                '<label for="description">Description </label>' +
                '<textarea id="description" class="mr-1 swal2-textarea col-md-6" placeholder="Description de l\'annonce" rows="5" required></textarea>' +
                '</div>' + '</div></form > ',
            focusConfirm: false,
            width: '80rem',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            preConfirm: async () => {
                const titre = Swal.getPopup().querySelector('#titre').value;
                const image = Swal.getPopup().querySelector('#image').value;
                const lien = Swal.getPopup().querySelector('#lien').value;
                const description = Swal.getPopup().querySelector('#description').value;
                let createData = {
                    titre,
                    image,
                    lien,
                    description
                }
                if (!titre || !image || !lien || !description) {
                    Swal.showValidationMessage('Veuillez remplir tous les champs.');
                } else {
                    const success = await dispatch(createNews(createData));
                    if (success)
                        dispatch(showSuccessMessage('Elément ajouté avec succès!'));
                    else
                        dispatch(showErrorMessage("Une erreur s'est produite lors de la création de l'élément."));
                }



            },
        });
    }


    // function showEditModal(singleItem) {
    //     Swal.fire({
    //         title: 'Modifier',
    //         html:
    //             '<form><div className="form-group"><label htmlFor="titre">Titre de l\'annonce</label>' +
    //             '<input id="titreEdit" class="swal2-input col-md-6" placeholder="Titre de l\'annonce" value="' + singleItem.titre + '"></div>' +
    //             ' <div className="form-group"><label htmlFor="lien">Lien de l\'image</label>' +
    //             '<input id="imageEdit" class="swal2-input col-md-6" placeholder="Lien de l\'image" value="' + singleItem.image + '"></div></form>' +
    //             '<div className="form-group"><label htmlFor="lien">Lien vers le site</label>' +
    //             '<input id="lienEdit" class="swal2-input col-md-6" placeholder="Lien vers le site" value="' + singleItem.lien + '">' +
    //             '<div className="form-group"><label for="description">Description </label>' + '<textarea id="descriptionEdit" class="mr-1 swal2-textarea col-md-6" placeholder="Description de l\'annonce" rows="10" required>' + singleItem.description + '</textarea>' +
    //             '</div></form > ',
    //         focusConfirm: false,
    //         width: '50rem',
    //         showCancelButton: true,
    //         cancelButtonText: 'Annuler',
    //         preConfirm: async () => {
    //             const updatedTitle = Swal.getPopup().querySelector('#titreEdit').value;
    //             const updatedImage = Swal.getPopup().querySelector('#imageEdit').value;
    //             const updatedLien = Swal.getPopup().querySelector('#lienEdit').value;
    //             const updatedDescription = Swal.getPopup().querySelector('#descriptionEdit').value;
    //             let updateData = {
    //                 slug: singleItem.slug,
    //                 titre: updatedTitle,
    //                 image: updatedImage,
    //                 lien: updatedLien,
    //                 description: updatedDescription
    //             }
    //             const success = await dispatch(updateVraiFaux(singleItem.id, updateData));
    //             if (success)
    //                 dispatch(showSuccessMessage('Mise à jour effectuée avec succès!'));
    //             else
    //                 dispatch(showErrorMessage("Une erreur s'est produite lors de la mise à jour de l'élément."));
    //         },
    //     });
    // }

    useEffect(() => {
        fetchCategories()
    }, [fetchCategories]);

    return (
        <div id="content-page" className="content-page">
             <div className="container-fluid">
                 {loading ? (
                     <div className="card-body">
                         <Spinner />
                     </div>
                 ) : 
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title text-primary">Toutes les Catégories</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body row">
                                    {!loading && categories.map((category) => (
                                        <div className="col-md-6 col-lg-3">
                                            <Link to={`/bot/news/category/${category.code}`}>
                                                <div className="iq-card iq-card-block iq-card-stretch iq-card-height categorieStyle">
                                                    <div className="iq-card-body">
                                                        <div className="d-flex align-items-center">
                                                            <div className="rounded-circle iq-card-icon">
                                                                <img src="/images/project-icon.png" className="img-fluid" alt="logo" />
                                                            </div>
                                                            <div className="text-left ml-3">
                                                                <h6>{category.titre}</h6>
                                                                <p className="mb-0">Code: {category.code}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                     </div>
                 }                
             </div>
         </div>
        
    );
}

const mapStateToProps = (state) => {
    return {
        news: state.news.news,
        categories: state.categories.categories,
        loading: state.news.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNews: () => dispatch(fetchNews()),
        fetchCategories: () => dispatch(fetchCategories())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);