const initialState = {
    actuDuJours: [],
    loading: true,
};

export const actudujourReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_ACTUDUJOUR_SUCCESS':
            return {
                ...state,
                actuDuJours: action.payload,
                loading: false,
            };
        
        case 'CREATE_ACTUDUJOUR_SUCCESS':
            const createdActuDuJour = action.payload;
            return {
                ...state,
                actuDuJours: [createdActuDuJour,...state.actuDuJours],
            };
        
        case 'UPDATE_ACTUDUJOUR_SUCCESS':
            const { updatedActu } = action.payload;
            return {
                ...state,
                actuDuJours: updatedActu,
            };

        case 'DELETE_ACTUDUJOUR_SUCCESS':
            let newActuDuJour = state.actuDuJours.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                actuDuJours: newActuDuJour,
            };
        
        default:
            return state;
    }
};
