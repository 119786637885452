import axios from 'axios';
import config from '../../config';

export const fetchDossiersSuccess = (dossiers) => {
    return {
        type: 'FETCH_DOSSIERS_SUCCESS',
        payload: dossiers
    };
};

export const fetchDossiers = () => {
    return (dispatch) => {
        axios.get(config.apiBaseUrl + "dossiers/all")
            .then((response) => {
                dispatch(fetchDossiersSuccess(response.data.returnValue));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};
export const createDossier = (newDossierData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "dossiers/create";
        const response = await axios.post(url, newDossierData);
        if (response.data.success) {
            const createdDossier= response.data.returnValue;
            dispatch({ type: 'CREATE_DOSSIERS_SUCCESS', payload: createdDossier });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('createDossier failed:', error);
        return false;
    }
};
    
export const updateDossier = (id, updatedDossiersData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "dossiers/update";
        const response = await axios.post(url, updatedDossiersData);
        if (response.data.success) {
            const url = config.apiBaseUrl + "dossiers/all";
            const response = await axios.get(url);
            let updatedDossier = response.data.returnValue;
            dispatch({ type: 'UPDATE_DOSSIERS_SUCCESS', payload: { updatedDossier } });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('updateDossier failed:', error);
        return false;
    }
};


export const deleteDossier = (id) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "dossiers/" + id;
        const response = await axios.delete(url);
        if (response.data.success) {
            dispatch({ type: 'DELETE_DOSSIERS_SUCCESS', payload: id });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('deleteDossier failed:', error);
        return false;
    }
};
