import axios from 'axios';
import config from '../../config';

// Action Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

// Action Creators
export const loginSuccess = (user, access_token) => {
  return {
    type: LOGIN_SUCCESS,
    payload: {
      user,
      access_token,
    },
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

// Thunk Action for Login
export const login = (email, password) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + 'auth/login';
    const response = await axios.post(url, { email, password });

    if (response.data && response.data.success) {
      const user = response.data.returnValue.user;
      const access_token = response.data.returnValue.access_token;

      // Dispatch login success action
      dispatch(loginSuccess(user, access_token));

      return user;
    } else {
      // Handle login failure here, possibly show an error message
      throw new Error('Login failed');
    }
  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};