import React from 'react';

function NotFoundPage() {
    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div
                        id="profile-profile"
                        className="col-md-12"
                    >
                        <div className="iq-card ">
                            <div className="iq-card-body ">
                                <div className="iq-error error-500 text-center">
                                    <img
                                        src="/images/error/03.png"
                                        alt={500}
                                        className="img-fluid iq-error-img"
                                    />
                                    <h2 className="mb-0 ">Oups ! Cette page est introuvable.</h2>
                                    <p>La page demandée n'existe pas.</p>
                                    <a href='/bot/dashboard' className="btn btn-primary mt-3 text-white">
                                        <i className="ri-home-4-line" />
                                        Accueil
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;
