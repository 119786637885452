/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAnalytics, fetchViews, fetchAllParents } from '../../services/actions/viewsActions';
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

 function DashboardPage(props) {
     const { loading, analytics, parents, views, fetchViews, fetchAnalytics,  } = props;
     const { user, isAuthenticated } = props;
     const dispatch = useDispatch();
     console.log({ views });

     let actualites = views.length ? views[0].children.sort((a, b) => b.count - a.count) : [];
     let sections = views.length ? views.slice(1).sort((a, b) => b.count - a.count) : [];

     const classes = ['like-block', 'repost-block', 'share-block', 'repost-block', 'view-block', 'post-block'];
     let names = {
         'actudujour': "Actu du Jour",
         "annonces": "Annonces",
         'vraifaux': "Vrai ou Faux",
         "bubs": "Publicités",
         'demandepub': "Demande De Pubs",
         "conseildesministres": "Conseil des Ministres",
         'dossiers': "Dossiers",
         "lefasoTv": "LeFaso Tv",
         'newsletter': "Newsletter",
         "devis": "Demande de Devis",
     }


     console.log({ sections });

     //  console.log({ parents });
     //  console.log({ views });

     useEffect(() => {
         fetchAnalytics();
         fetchAllParents();
         fetchViews();
     }, [fetchAnalytics, fetchAllParents, fetchViews]);
     return (
         <div id="content-page" className="content-page">
             {
                 loading ? (
                     <div className="card-body" >
                         <Spinner />
                     </div>
                 ) : (
                     <div className="container-fluid">

                         <div className="row">
                             <div className="col-sm-12">
                                 <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                     <div className="iq-card-body">
                                         <div className="iq-advance-course d-flex align-items-center justify-content-between">
                                             <div className="left-block">
                                                 <div className="d-flex align-items-center">
                                                     <img src="/images/page-img/29.png" className="img-fluid" alt="icon-img" />
                                                     <div className=" ml-3">
                                                         <h4 className>La Gestion De Votre Chatbot WhatsApp Dans Un Seul Endroit </h4>
                                                         <p className="mb-0">Gardez un oeil sur l'audience générée par le chatbot WhatsApp et rapprochez-vous de votre clientèle.</p>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                         
                             <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card bg-primary">
                             <div className="iq-card-header d-flex justify-content-between">
                                 <div className="iq-header-title">
                                     <h4 className="card-title text-white">Statistics</h4>
                                 </div>
                             </div>
                             <div className="iq-card-body ">

                                 <div className="row">
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.actuDuJour}</h2>
                                                         <p className="fontsize-sm m-0 text-dark">Actu du jour</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-warning ">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.actualites}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Actualités</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-warning ">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>

                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card ">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.newsletter}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Abonnés à la Newsletter</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon  dark-icon-light iq-bg-primary ">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card ">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.pubs}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Pubs</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-info ">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>

                                 </div>
                                 <div className="row">
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card ">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.demandepub}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Demande de Pubs</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-danger">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.conseilministres}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Conseil des Ministres</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-warning ">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card ">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.dossiers}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Dossiers</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon  dark-icon-light iq-bg-primary ">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card ">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.leFasoTv}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">LeFasoTv</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-info ">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card ">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.petitesAnnonces}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Petites Annonces</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-danger">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="col-sm-6 col-md-6 col-lg-3">
                                         <div className="iq-card ">
                                             <div className="iq-card-body ">
                                                 <div className="d-flex d-flex align-items-center justify-content-between">
                                                     <div>
                                                         <h2>{analytics.vraixFaux}</h2>
                                                             <p className="fontsize-sm m-0 text-dark">Vrai Ou Faux</p>
                                                     </div>
                                                     <div className="rounded-circle iq-card-icon iq-bg-danger">
                                                             <i className="ri-star-line" />
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     </div>

                                 </div>

                             </div>
                         </div>

                         <div className="row">
                                 <div className="col-md-6 col-lg-12 iq-bg-info">
                                 <div className="iq-card-block iq-card-stretch iq-card-height iq-card ">
                                     <div className="iq-card-header d-flex justify-content-between ">
                                         <div className="iq-header-title">
                                                 <h4 className="card-title text-primary">Consultation des Actualités</h4>
                                         </div>
                                     </div>
                                         <div className="iq-card-body">
                                             <div className="m-0 p-0 d-flex flex-wrap align-items-center justify-content-between">
                                                 {
                                                     actualites.length != 0 && actualites.map((singleItem, index) => {
                                                         if (singleItem.source === "chatbot") {
                                                             const randomClass = classes[index % 2];
                                                             return (
                                                                 <div key={index} className="col-md-6">
                                                                     <div className="d-flex align-items-center  mt-3">
                                                                         <div className={`${randomClass} iq-scale-border mr-3`} />
                                                                         <div className="iq-scale-content">
                                                                             <h6>{singleItem.count}  <i className="ri-eye-line" /></h6>
                                                                             <h6 className="mb-0 card-title">{singleItem.nom}</h6>
                                                                             <span class="badge badge-pill iq-bg-info my-2 text-dark">
                                                                                 <i className="ri-calendar-line mr-1" />
                                                                                 Dernière Interaction: {singleItem.updated_at.slice(0, 10)}
                                                                             </span>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             );
                                                         }
                                                         return null;
                                                     })}
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                                 <div className="col-md-6 col-lg-12 iq-bg-primary">
                                     <div className="iq-card-block iq-card-stretch iq-card-height iq-card ">
                                         <div className="iq-card-header d-flex justify-content-between ">
                                             <div className="iq-header-title">
                                                 <h4 className="card-title text-primary">Consultation par Sections</h4>
                                             </div>
                                         </div>
                                         <div className="iq-card-body">
                                             <div className="m-0 p-0 d-flex flex-wrap align-items-center justify-content-between">
                                                 {
                                                     sections.length != 0 && sections.map((singleItem, index) => {
                                                         if (singleItem.source === "chatbot") {
                                                             const randomClass = classes[index % 2];
                                                             return (
                                                                 <div key={index} className="col-md-6">
                                                                     <div className="d-flex align-items-center  mt-3">
                                                                         <div className={`${randomClass} iq-scale-border mr-3`} />
                                                                         <div className="iq-scale-content">
                                                                             <h6>{singleItem.count}  <i className="ri-eye-line" /></h6>

                                                                             <h6 className="mb-0 card-title">{names[singleItem.nom]}</h6>
                                                                             <span class="badge badge-pill iq-bg-info my-2 text-dark">
                                                                                 <i className="ri-calendar-line mr-1" />
                                                                                 Dernière Interaction: {singleItem.updated_at.slice(0, 10)}
                                                                             </span>
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             );
                                                         }
                                                         return null;
                                                     })}
                                             </div>
                                         </div>
                                     </div>
                                 </div>

                             <div className="col-lg-12">
                                 <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                                     <div className="iq-card-body">
                                         <div className="image-container">
                                             <img src="https://www.consulat-burkinaespagne.org/cmsupload/arton73272.jpg" className="centered-image" />
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 )}
             
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        views: state.views.views,
        analytics: state.analytics.analytics,
        parents: state.parents.parents,
        loading: state.views.loading,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        fetchViews: () => dispatch(fetchViews()),
        fetchAnalytics: () => dispatch(fetchAnalytics()),
        fetchAllParents: () => dispatch(fetchAllParents()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);