/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function FloatNav(props) {

    return (
        <div></div>
        // <nav className="iq-float-menu">
        //     <input type="checkbox" href="#" className="iq-float-menu-open" name="menu-open" id="menu-open" />
        //     <label className="iq-float-menu-open-button" htmlFor="menu-open">
        //         <span className="lines line-1" />
        //         <span className="lines line-2" />
        //         <span className="lines line-3" />
        //     </label>
        //     <button className="iq-float-menu-item bg-info" data-toggle="tooltip" data-placement="top" title="Direction Mode" data-mode="rtl"><i className="ri-text-direction-r" /></button>
        //     <button className="iq-float-menu-item bg-danger" data-toggle="tooltip" data-placement="top" title="Color Mode" id="dark-mode" data-active="true"><i className="ri-sun-line" /></button>
        //     <button className="iq-float-menu-item bg-warning" data-toggle="tooltip" data-placement="top" title="Comming Soon"><i className="ri-palette-line" /></button>
        // </nav>


    );
}