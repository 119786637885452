import axios from 'axios';
import config from '../../config';

const CUSTOM_FIELD_NAME = 'Channel';
const CUSTOM_FIELD_VALUE = 'fasoNet';

const header = { Authorization: `Bearer ${config.botToken}` };

export const getCustomFieldId = () => async (dispatch) => {
    try {
        const response = await axios.get(config.apiBaseUrl + 'getCustomFields', { headers: header });
        const customFieldsList = response.data.data;
        for (const cf of customFieldsList) {
            for (const [key, value] of Object.entries(cf)) {
                if (value === CUSTOM_FIELD_NAME) {
                    return cf.id;
                    //dispatch({ type: 'SET_CUSTOM_FIELD_ID', payload: cf.id });
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const getNewSubscribers = (customFieldId) => async (dispatch) => {
    try {
        const response = await axios.get(config.apiBaseUrl + `findByCustomField/${customFieldId}/${CUSTOM_FIELD_VALUE}`, { headers: header });
        dispatch({ type: 'SET_SUBSCRIBERS', payload: response.data.data.sort((a, b) => a.name.localeCompare(b.name)) });
    } catch (error) {
        console.log(error);
    }
};