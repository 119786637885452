import axios from 'axios';
import config from '../../config';

export const fetchChannelsSuccess = (channels) => {
    return {
        type: 'FETCH_CHANNELS_SUCCESS',
        payload: channels
    };
};

export const fetchChannels = () => {
    return (dispatch) => {
        axios.get(config.apiBaseUrl + "le-faso-tv/all")
            .then((response) => {
                dispatch(fetchChannelsSuccess(response.data.returnValue));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};
export const createChannel = (newChannelData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "le-faso-tv/create";
        const response = await axios.post(url, newChannelData);
        if (response.data.success) {
            const createdChannel = response.data.returnValue;
            dispatch({ type: 'CREATE_CHANNEL_SUCCESS', payload: createdChannel });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('createChannel failed:', error);
        return false;
    }
};
    
export const updateChannel = (id,updatedChannelData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "le-faso-tv/update";
        const response = await axios.post(url, updatedChannelData);
        if (response.data.success) {
            const url = config.apiBaseUrl + "le-faso-tv/all";
            const response = await axios.get(url);
            let updatedChannels = response.data.returnValue;
            dispatch({ type: 'UPDATE_CHANNEL_SUCCESS', payload: { updatedChannels } });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('updateChannel failed:', error);
        return false;
    }
};


export const deleteChannel = (id) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "le-faso-tv/" + id;
        const response = await axios.delete(url);
        if (response.data.success) {
            dispatch({ type: 'DELETE_CHANNEL_SUCCESS', payload: id });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('deleteChannel failed:', error);
        return false;
    }
};
