import axios from 'axios';
import config from '../../config';

export const fetchNewsletterSuccess = (newsletter) => {
  return {
    type: 'FETCH_NEWSLETTER_SUCCESS',
    payload: newsletter
  };
};

export const deleteNewsletterSuccess = (id) => {
  return {
    type: 'DELETE_NEWSLETTER_SUCCESS',
    payload: id
  };
};
export const fetchNewsLetter = () => {
  return (dispatch) => {
    axios.get(config.apiBaseUrl +"newsletter/all")
      .then((response) => {
        //console.log(response.data.returnValue)
        dispatch(fetchNewsletterSuccess(response.data.returnValue));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};


export const deleteNewsLetter = (id) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "newsletter/" + id;
    const response = await axios.delete(url);
    if (response.data.success) {
      dispatch({ type: 'DELETE_NEWSLETTER_SUCCESS', payload: id });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('DeleteNewsLetter failed:', error);
    return false;
  }
};

// export const deleteNewsLetter = (id) => {
//   return (dispatch) => {
//     axios.delete(config.apiBaseUrl + "newsletter/" + id)
//       .then((response) => {
//         dispatch(deleteNewsletterSuccess(response.data.returnMsg));
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };
// }