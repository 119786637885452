import axios from 'axios';
import config from '../../config';

export const fetchNewsSuccess = (news) => {
  return {
    type: 'FETCH_NEWS_SUCCESS',
    payload: news
  };
};

export const fetchNews = () => {
  return (dispatch) => {
    axios.get(config.apiBaseUrl +"actualites/all")
      .then((response) => {
        //console.log(response.data.returnValue)
        dispatch(fetchNewsSuccess(response.data.returnValue));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const createNews = (newData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "actualites/create";
    const response = await axios.post(url, newData);
    if (response.data.success) {
      const createdNews = response.data.returnValue;
      dispatch({ type: 'CREATE_NEWS_SUCCESS', payload: createdNews });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('createNews failed:', error);
    return false;
  }
};

export const updateNews = (updatedNewsData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "actualites/update";
    const response = await axios.post(url, updatedNewsData);
    if (response.data.success) {
      const url = config.apiBaseUrl + "actualites/all";
      const response = await axios.get(url);
      let updatedNews = response.data.returnValue;
      dispatch({ type: 'UPDATE_NEWS_SUCCESS', payload: { updatedNews } });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('updateNews failed:', error);
    return false;
  }
};


export const deleteNews = (id) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "actualites/" + id;
    const response = await axios.delete(url);
    if (response.data.success) {
      dispatch({ type: 'DELETE_NEWS_SUCCESS', payload: id });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('deleteNews failed:', error);
    return false;
  }
};