/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import ReactPlayer from 'react-player'
import { connect } from 'react-redux';
import { fetchChannels, createChannel, updateChannel,deleteChannel } from '../../services/actions/channelsActions';
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

function ChannelsPage(props) {

    const { loading, channels, fetchChannels } = props;
    const dispatch = useDispatch();

    function showCreateModal() {
        Swal.fire({
            title: 'Ajouter',
            html:
                '<form><div className="form-group"><label htmlFor="titre">Titre de la vidéo</label>' +
                '<input id="titre" class="swal2-input col-md-6" placeholder="Titre" value=""></div>' +
                ' <div className="form-group"><label htmlFor="lien">Lien youtube</label>' +
                '<input id="lien" class="swal2-input col-md-6" placeholder="Lien de la vidéo youtube" value=""></div></form>',
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Envoyer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
                cancelButton: 'btn btn-danger bg-danger',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            preConfirm: async () => {

                const titre = Swal.getPopup().querySelector('#titre').value;
                const lien = Swal.getPopup().querySelector('#lien').value;
                if (!titre || !lien) {
                    Swal.showValidationMessage('Veuillez remplir tous les champs.');
                } else {
                    let createData = {
                        titre,
                        lien
                    }
                    const success = await dispatch(createChannel(createData));
                    // Show a success message
                    dispatch(showSuccessMessage('Elément ajouté avec succès!!!'));
                    //Swal.fire('Ajouté!', "Elément ajouté avec success!!!", 'success');
                }
                
            },
        });
    }


    function showEditModal(id,slug,originalTitle, originalLink) {
        Swal.fire({
            title: 'Modifier',
            html:
                '<form><div className="form-group"><label htmlFor="titre">Titre de la vidéo</label>'+
                '<input id="titre" class="swal2-input col-md-6" placeholder="Titre" value="' + originalTitle + '"></div>' +
                ' <div className="form-group"><label htmlFor="lien">Lien youtube</label>' +
                '<input id="lien" class="swal2-input col-md-6" placeholder="Lien de la vidéo youtube" value="' + originalLink + '"></div></form>',

            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Envoyer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
                cancelButton: 'btn btn-danger bg-danger',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            preConfirm: async () => {
                const updatedTitle = Swal.getPopup().querySelector('#titre').value;
                const updatedLink = Swal.getPopup().querySelector('#lien').value;
                let updateData = {
                    slug,
                    titre: updatedTitle,
                    lien: updatedLink
                }
                const success = await dispatch(updateChannel(id,updateData));
                // Show a success message
               // Swal.fire('Modifié!', "Mise à jour avec success!!!", 'success');
                dispatch(showSuccessMessage('Mise à jour effectuée avec succès!'));
            },
        });
    }

    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deleteChannel(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                   // Swal.fire('Supprimé!', "Suppression avec Succès", 'success');
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                    //Swal.fire('Erreur', "Une erreur s'est produite lors de la suppression de l'élément.", 'error');
                }
            }
        });
    };
    
    useEffect(() => {
        fetchChannels();
    }, [fetchChannels]);

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                {loading ? (
                    <div className="card-body">
                        <Spinner />
                    </div>
                ) :
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title text-primary">Liste de Vidéos Youtube pour LeFasoTV</h4>
                                    </div>
                                    <div className="iq-card-header-toolbar d-flex align-items-center">

                                        <button className="btn btn-info" onClick={() => showCreateModal()}>
                                            <i className="ri-add-circle-line" />
                                            Ajouter
                                        </button>
                                    </div>
                                </div>
                                <div className="iq-card-body row">
                                    {!loading && channels.map((channel) => (
                                        <div className="col-sm-12 col-lg-6 ">
                                            <div className="iq-card lefasoTV">
                                                <div className="iq-card-header d-flex justify-content-between">
                                                    <div className="iq-header-title">
                                                        <h5 className="card-title">{channel.titre}</h5>
                                                        <div className='mt-2'>
                                                            <span class="badge badge-pill iq-bg-warning my-2 text-dark">
                                                                <i className="ri-calendar-line mr-1" />
                                                                {channel.created_at.slice(0, 10)}
                                                            </span>
                                                            <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                                                                <i className="ri-share-line mr-1" />
                                                                <a href={channel.lien} target="_blank" > Lien vers la Vidéo</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="iq-card-body">
                                                    <div className="embed-responsive embed-responsive-21by9">
                                                        <ReactPlayer url={channel.lien} controls height={100} />
                                                    </div>
                                                    <div className='mt-3 text-center'>
                                                        <button type="button" class="btn btn-warning ml-0" onClick={() => showEditModal(channel.id, channel.slug, channel.titre, channel.lien)}>
                                                            <i class="ri-pencil-fill font-size-16"></i>
                                                            Modifier
                                                        </button>
                                                        <button type="button" class="btn btn-danger ml-3" onClick={() => showDeleteConfirmation(channel.id)}>
                                                            <i class="ri-delete-bin-line font-size-16"></i>
                                                            Supprimer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>   
            }
               
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        channels: state.channels.channels,
        loading: state.channels.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchChannels: () => dispatch(fetchChannels()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelsPage);