/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Sidebar() {

    const location = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);

    const menuItems = [
        { title: 'Tableau De Bord', path: '/bot/dashboard', icon: 'ri-home-7-line' },
        { title: 'Actu Du Jour', path: '/bot/actu-du-jour', icon: 'ri-star-line' },
        { title: 'Actualités', path: '/bot/actualites', icon: 'ri-profile-line' },
        { title: 'Catégories', path: '/bot/categories', icon: 'ri-profile-line' },
        { title: 'LeFasoTV', path: '/bot/tv', icon: 'ri-4k-line' },
        { title: 'Vrai Ou Faux', path: '/bot/vrai-ou-faux', icon: 'ri-file-fill' },
        { title: 'Dossiers', path: '/bot/dossiers', icon: 'ri-file-fill' },
        { title: 'Conseils de Ministres', path: '/bot/conseils-de-ministres', icon: 'ri-file-fill' },
        { title: 'Petites Annonces', path: '/bot/petites-annonces', icon: 'ri-computer-fill' },
        { title: 'Publicités', path: '/bot/pubs', icon: 'ri-reply-fill' },
        { title: 'Demande de Pub', path: '/bot/demande-de-pub', icon: 'ri-reply-fill' },
        { title: 'Newsletter', path: '/bot/newsletter', icon: 'ri-file-list-line' },
        { title: 'Contacts', path: '/bot/contacts', icon: 'ri-user-add-line' },
        // { title: 'Conversations', path: '/bot/chat', icon: 'ri-message-line' },
        { title: 'A Propos', path: '/bot/about', icon: 'ri-user-add-line' },
        { title: 'Equipe', path: '/bot/equipe', icon: 'ri-group-line' },
    ];

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    function logout() {
        sessionStorage.removeItem('isAuthenticated');
        let url = '/auth/login';
        window.location.href = url;
    }

    return (
        <div className="iq-sidebar">
            <div className="iq-sidebar-logo d-flex justify-content-between">
                <a href="/">
                    <span className="SideBarName">LeFasoNetBot</span>
                    <img src='/images/logo.jpg' className='ml-5' style={{ borderRadius: 20 }} />
                </a>
                {/* <div className="iq-menu-bt align-self-center">
                    <div className="wrapper-menu" onClick={toggleMenu}>
                        <div className="menu-close">
                            <i className="ri-arrow-left-line" />
                        </div>
                        <div className="menu-open">
                            <i className="ri-arrow-right-line" />
                        </div>
                    </div>
                </div> */}
            </div>
            <div id="sidebar-scrollbar">
                <nav className="iq-sidebar-menu">
                    <ul id="iq-sidebar-toggle" className="iq-menu">
                        {menuItems.map((menuItem, index) => (
                            <li key={index} className={location.pathname.includes(menuItem.path) ? 'active' : ''}>
                                <Link to={menuItem.path}>
                                    <i className={menuItem.icon} />
                                    <span>{menuItem.title}</span>
                                </Link>
                            </li>
                        ))}
                        <li className={location.pathname.includes('Déconnexion') ? 'active' : ''}>
                            <a href="#"  onClick={logout}>
                                <i className='ri-lock-line' />
                                <span>Déconnexion</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="p-3" />
            </div>
        </div>
    );
}