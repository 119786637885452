const initialState = {
    users: [],
    loading: true,
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USERS_SUCCESS':
            return {
                ...state,
                users: action.payload,
                loading: false,
            };

        case 'CREATE_USER_SUCCESS':
            const createdUser = action.payload;
            return {
                ...state,
                users: [createdUser, ...state.users],
            };

        case 'UPDATE_USER_SUCCESS':
            const { updatedUsers } = action.payload;
            return {
                ...state,
                users: updatedUsers,
            };

        case 'DELETE_USER_SUCCESS':
            let newUsers = state.users.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                users: newUsers,
            };

        default:
            return state;
    }
};
