import { combineReducers } from 'redux';
import { newsReducer } from "./reducers/newsReducer";
import { categoriesReducer } from "./reducers/categoriesReducer";
import { channelsReducer } from "./reducers/channelsReducer";
import { contactsReducer } from "./reducers/contactsReducer";
import { reducer as toastrReducer } from 'react-redux-toastr';

import { authReducer } from "./reducers/authReducer";
import { newsletterReducer } from './reducers/newsletterReducer';
import { demandePubsReducer } from './reducers/demandePubsReducer';
import { pubsReducer } from './reducers/pubsReducer';
import { vraixfauxReducer } from './reducers/vraixfauxReducer';
import { actudujourReducer } from './reducers/actudujourReducer';
import { dossiersReducer } from './reducers/dossiersReducer';
import { conseilsReducer } from './reducers/conseilsReducer';
import { viewsReducer } from './reducers/viewsReducer';
import { petitesAnnoncesReducer } from './reducers/petitesAnnoncesReducer';
import { usersReducer } from './reducers/usersReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  actuDuJours: actudujourReducer,
  newsletter: newsletterReducer,
  dossiers: dossiersReducer,
  users: usersReducer,
  views: viewsReducer,
  analytics: viewsReducer,
  parents: viewsReducer,
  pubs: pubsReducer,
  demandepubs: demandePubsReducer,
  vraixfaux: vraixfauxReducer,
  annonces: petitesAnnoncesReducer,
  news: newsReducer,
  conseils: conseilsReducer,
  categories: categoriesReducer,
  newsByCat: categoriesReducer,
  channels: channelsReducer,
  contacts: contactsReducer,
  toastr: toastrReducer
});

export default rootReducer;