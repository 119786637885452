const initialState = {
    categories: [],
    newsByCat:[],
    loading: true,
};

export const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CATEGORIES_SUCCESS':
            return {
                ...state,
                categories: action.payload,
                loading: false,
            };
        
        case 'FETCH_NEWS_BY_CATEGORY_SUCCESS':
            return {
                ...state,
                newsByCat: action.payload,
                loading: false,
            };
        
        default:
            return state;
    }
};
