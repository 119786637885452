const initialState = {
    conseils: [],
    loading: true,
};

export const conseilsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CONSEILS_SUCCESS':
            return {
                ...state,
                conseils: action.payload,
                loading: false,
            };
        
        case 'CREATE_CONSEILS_SUCCESS':
            const createdConseil = action.payload;
            return {
                ...state,
                conseils: [createdConseil, ...state.conseils],
            };
        
        case 'UPDATE_CONSEILS_SUCCESS':
            const { updatedConseil} = action.payload;
            return {
                ...state,
                conseils: updatedConseil,
            };

        case 'DELETE_CONSEILS_SUCCESS':
            let newConseil = state.conseils.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                conseils: newConseil,
            };
        
        default:
            return state;
    }
};
