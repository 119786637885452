/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchNews, createNews, updateNews, deleteNews, } from '../../services/actions/newsActions';
import { fetchCategories, fetchNewsByCategory } from '../../services/actions/categoriesActions';
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

function NewsByCategoryPage(props) {

    const { id } = useParams();
    const dispatch = useDispatch();
    const { loading, newsByCat, fetchNewsByCategory } = props;
   // const filteredNews = news.filter(news => news.category_id == id);
    const { code } = useParams();
    useEffect(() => {
        // fetchNews();
        fetchNewsByCategory(code)
    }, [code,fetchNewsByCategory]);

    const showItem = (item) => {
        const content = `
        <div class="card card-height bg-light-gray">
                <div class="card-body">
                 <h5 class="card-title text-center text-bold">${item.titre}</h5>
                 <span class="badge badge-pill iq-bg-success my-2 text-dark">Catégorie: ${item.category ? item.category.titre : '-'}</span>
                    <span class="mx-2 badge badge-pill iq-bg-dark my-2 text-dark">Date:${item.created_at.slice(0, 10)}</span>
                    <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                       <a href=${item.lien} target="_blank" > Lien vers le site</a>
                    </span>
                    <p class="card-text text-justify">${item.description}</p>
                </div>
            </div>`;
        Swal.fire({
            title: item.title,
            html: content,
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: false,
        });
    };

    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deleteNews(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                }
            }
        });
    };

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    {newsByCat.length > 0 && newsByCat.map((newsByCategory) => (
                        <div className="col-sm-6">
                            <div className="card iq-mb-3">
                                <img src={newsByCategory.image} className="card-img-top imageProper" alt="#" />
                                <div className="iq-card-body">
                                    <h4 className="card-title">{newsByCategory.titre.length > 100 ? newsByCategory.titre.slice(0, 100) + '...' : newsByCategory.titre}</h4>
                                    <span class="badge badge-pill iq-bg-success my-2 text-dark">Catégorie:{newsByCategory.category ? newsByCategory.category.titre : '-'}</span>
                                    <span class="mx-2 badge badge-pill iq-bg-dark my-2 text-dark">Date:{newsByCategory.created_at.slice(0, 10)}</span>
                                    <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                                        <i className="ri-share-line mr-1" />
                                        <a href={newsByCategory.lien} target="_blank" > Lien vers le site</a>
                                    </span>
                                    <p className="card-text">{newsByCategory.description.slice(0,400)}... </p>

                                    <div className="card-footer bg-light-gray">
                                        <div className=' text-center'>
                                            <button type="button" class="btn btn-info ml-0" onClick={() => showItem(newsByCategory)}>
                                                <i class="ri-eye-line font-size-16"></i>
                                                Consulter
                                            </button>
                                            {/* <button type="button" class="btn btn-warning ml-3" onClick={() => showEditForm(newsByCategory)}>
                                                <i class="ri-pencil-fill font-size-16"></i>
                                                Modifier
                                            </button> */}
                                            <button type="button" class="btn btn-danger ml-3" onClick={() => showDeleteConfirmation(newsByCategory.id)}>
                                                <i class="ri-delete-bin-line font-size-16"></i>
                                                Supprimer
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {newsByCat.length == 0 &&
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Résultats de la requete</h4>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <div className="col-sm-12">
                                        <div className="alert alert-info" role="alert">
                                            <div className="iq-alert-text">
                                                <h5 className="alert-heading">Well done!</h5>
                                                <p>La requete s'est bien passée mais nous n'avons malheureusement pas trouvé d'actualité(s) pour cette catégorie.
                                                </p>
                                                <hr />
                                                <a href="/bot/actualites" className="btn dark-icon btn-light">Retour à la liste des actualités</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        newsByCat: state.newsByCat.newsByCat,
        loading: state.categories.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNewsByCategory: (code) => dispatch(fetchNewsByCategory(code))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsByCategoryPage);



