import axios from 'axios';
import config from '../../config';

export const fetchActuDuJourSuccess = (actuDuJours) => {
    return {
        type: 'FETCH_ACTUDUJOUR_SUCCESS',
        payload: actuDuJours
    };
};

export const fetchActuDuJours = () => {
    return (dispatch) => {
        axios.get(config.apiBaseUrl + "actu-du-jour/all")
            .then((response) => {
                dispatch(fetchActuDuJourSuccess(response.data.returnValue));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};
export const createActuDuJour = (newChannelData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "actu-du-jour/create";
        const response = await axios.post(url, newChannelData);
        if (response.data.success) {
            const createdActu = response.data.returnValue;
            dispatch({ type: 'CREATE_ACTUDUJOUR_SUCCESS', payload: createdActu });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('createActuDuJour failed:', error);
        return false;
    }
};
    
export const updateActuDuJour = (updatedActuData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "actu-du-jour/update";
        const response = await axios.post(url, updatedActuData);
        if (response.data.success) {
            const url = config.apiBaseUrl + "actu-du-jour/all";
            const response = await axios.get(url);
            let updatedActu = response.data.returnValue;
            dispatch({ type: 'UPDATE_ACTUDUJOUR_SUCCESS', payload: { updatedActu } });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('updateActuDuJour failed:', error);
        return false;
    }
};


export const deleteActuDuJour = (id) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "actu-du-jour/" + id;
        const response = await axios.delete(url);
        if (response.data.success) {
            dispatch({ type: 'DELETE_ACTUDUJOUR_SUCCESS', payload: id });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('deleteActuDuJour failed:', error);
        return false;
    }
};
