const initialState = {
  annonces: [],
  loading: true,
};
export const petitesAnnoncesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ANNONCES_SUCCESS':
      return {
        ...state,
        annonces: action.payload,
        loading: false,
      };
    
    case 'CREATE_ANNONCES_SUCCESS':
      const createdAnnonces = action.payload;
      return {
        ...state,
        annonces: [createdAnnonces, ...state.annonces],
      };

    case 'UPDATE_ANNONCES_SUCCESS':
      const { newAnnonces} = action.payload;
      return {
        ...state,
        annonces: newAnnonces,
      };
    
    case 'DELETE_ANNONCES_SUCCESS':
      const updatedAnnonces = state.annonces.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        annonces: updatedAnnonces,
        loading: false,
      };
  
    default:
      return state;
  }
};