const initialState = {
    dossiers: [],
    loading: true,
};

export const dossiersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DOSSIERS_SUCCESS':
            return {
                ...state,
                dossiers: action.payload,
                loading: false,
            };
        
        case 'CREATE_DOSSIERS_SUCCESS':
            const createdDossier = action.payload;
            return {
                ...state,
                dossiers: [createdDossier, ...state.dossiers],
            };
        
        case 'UPDATE_DOSSIERS_SUCCESS':
            const { updatedDossier} = action.payload;
            return {
                ...state,
                dossiers: updatedDossier,
            };

        case 'DELETE_DOSSIERS_SUCCESS':
            let newDossier = state.dossiers.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                dossiers: newDossier,
            };
        
        default:
            return state;
    }
};
