/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchActuDuJours, createActuDuJour, updateActuDuJour, deleteActuDuJour } from '../../services/actions/actudujourActions';
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

function ActuDuJourPage(props) {

    const { loading, actuDuJours, fetchActuDuJours } = props;
    const { user, isAuthenticated } = props;
    const [showForm, setShowForm] = useState(false);
    const [showCardList, setShowCardList] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [editState, setEditState] = useState(false);
    const [formloading, setFormLoading] = useState(false);

    const maxDescriptionLength = 300;

    //console.log({ actuDuJours })
    const dispatch = useDispatch();

    function showCreateForm() {
        setShowForm(true);
        setShowCardList(false);
    }

    function hideCreateForm() {
        setShowForm(false);
        setShowCardList(true);
        setFormData({});
        setFormErrors({});
        setEditState(false);
    }

    const [formData, setFormData] = useState({
        titre: '',
        image: '',
        lien: '',
        description: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const validateForm = (data) => {
        const errors = {};
        if (!data.titre || !data.description) {
            errors.global = 'Veuillez remplir les champs';
        } else {
            if (!data.titre.trim()) {
                errors.titre = 'Le titre est requis';
            }
            if (!data.description.trim()) {
                errors.description = 'La description est requise';
            }
        }

        return errors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log({ formData })
        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({});
        setFormLoading(true);
        if (editState) {
            const success = await dispatch(updateActuDuJour(formData));
            if (success) {
                dispatch(showSuccessMessage('Mise à jour effectuée avec succès!'));
                hideCreateForm();
            } else {
                dispatch(showErrorMessage('Une erreur s\'est produite lors de la mise à jour.'));
            }
        } else {
            formData.user_id = user.id;
            const success = await dispatch(createActuDuJour(formData));
            if (success) {
                dispatch(showSuccessMessage('Élément ajouté avec succès!'));
                hideCreateForm();
            } else {
                dispatch(showErrorMessage('Une erreur s\'est produite lors de l\'ajout.'));
            }
        }
        setFormLoading(false);
    };

    const showItem = (item) => {
        const content = `
        <div class="card card-height bg-light-gray">
                <div class="card-body">
                 <h5 class="card-title text-center text-bold">${item.titre}</h5>
                    <span class="badge badge-pill iq-bg-success my-2 text-dark">Catégorie: ${item.category ? item.category.titre : '-'}</span>
                    <span class="mx-2 badge badge-pill iq-bg-dark my-2 text-dark">Date:${item.created_at.slice(0, 10)}</span>
                    <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                       <a href=${item.lien} target="_blank" > Lien vers le site</a>
                    </span>
                    <p class="card-text text-justify">${item.description}</p>
                </div>
            </div>`;
        Swal.fire({
            title: item.title,
            html: content,
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Fermer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: false,
        });
    };

    function showEditForm(actu) {
        setEditState(true);
        formData.slug = actu.slug;
        formData.titre = actu.titre;
        formData.image = actu.image;
        formData.lien = actu.lien;
        formData.description = actu.description;
        showCreateForm();
    }

    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deleteActuDuJour(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                }
            }
        });
    };

    //let colors = ['info', 'purple', 'info', 'purple',]
    useEffect(() => {
        fetchActuDuJours();
    }, [fetchActuDuJours]);

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                {loading ? (
                    <div className="card-body">
                        <Spinner />
                    </div>
                ) :
                    <div className="row">
                        {showForm && (
                            <div className="col-sm-12 col-lg-12">
                                <div className="iq-card ">
                                    <div className="iq-card-header d-flex justify-content-between ">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Ajouter</h4>
                                            {formErrors.global && (
                                                <div className="invalid-feedback">{formErrors.global}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="iq-card-body ">
                                        <form className="">
                                            <div role="group" className="form-group">
                                                <label
                                                    htmlFor="title"
                                                    className="d-block"

                                                >
                                                    Titre
                                                </label>
                                                <div>
                                                    <input
                                                        id="titre"
                                                        type="text"
                                                        name="titre"
                                                        className={`form-control ${formErrors.titre ? 'is-invalid' : ''}`}
                                                        value={formData.titre}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {formErrors.titre && (
                                                        <div className="invalid-feedback">{formErrors.titre}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label htmlFor="image" className="d-block">
                                                    Lien Image:
                                                </label>
                                                <div>
                                                    <input
                                                        id="image"
                                                        type="link"
                                                        name="image"
                                                        className={`form-control ${formErrors.image ? 'is-invalid' : ''}`}
                                                        value={formData.image}
                                                        onChange={handleInputChange}

                                                    />

                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label htmlFor="lien" className="d-block" >
                                                    Lien sur le site:
                                                </label>
                                                <div>
                                                    <input
                                                        id="lien"
                                                        type="link"
                                                        name="lien"
                                                        className={`form-control ${formErrors.lien ? 'is-invalid' : ''}`}
                                                        value={formData.lien}
                                                        onChange={handleInputChange}
                                                    />

                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label
                                                    htmlFor="exampleFormControlTextarea1"
                                                    className="d-block"
                                                >
                                                    Contenu
                                                </label>
                                                <div>
                                                    <textarea
                                                        id="description"
                                                        name="description"
                                                        rows={5}
                                                        wrap="soft"
                                                        className={`form-control ${formErrors.description ? 'is-invalid' : ''}`}
                                                        value={formData.description}
                                                        onChange={handleInputChange}
                                                    />
                                                    {formErrors.description && (
                                                        <div className="invalid-feedback">{formErrors.description}</div>
                                                    )}
                                                </div>
                                            </div>


                                            <button type="submit" className="btn btn-success" onClick={handleSubmit} disabled={formloading}>
                                                {formloading ? 'Envoi en cours...' : 'Envoyer'}
                                            </button>
                                            <button onClick={() => hideCreateForm()} className="btn iq-bg-danger ml-3 btn-none">
                                                Annuler
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="col-lg-12">
                            <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title text-primary">Actualité du Jour</h4>
                                    </div>
                                    <div className="iq-card-header-toolbar d-flex align-items-center">

                                        <button className="btn btn-info" onClick={() => showCreateForm()}>
                                            <i className="ri-add-circle-line" />
                                            Ajouter
                                        </button>
                                    </div>
                                </div>
                                <div className="iq-card-body row custom-notes-space">
                                   
                                    {!loading && actuDuJours.map((actu,index) => (
                                        <div className="col-lg-4 col-md-6">
                                            <div key={index} className={`card card-height card-bottom-border-${index === 0 ? 'primary' : 'primary'} note-detail`}>
                                                <div className="card-header d-flex justify-content-between pb-1">
                                                    <div className="icon iq-icon-box-2 icon-border-primary rounded">
                                                        <i className="ri-star-line m-2" /> 
                                                    </div>

                                                </div>
                                                <div className="card-body rounded">
                                                    <div className="media flex-wrap align-items-top">
                                                        <h4 className="card-title mr-3 actuTitle">{actu.titre.length > 100 ? actu.titre.slice(0, 100) + '...' : actu.titre}</h4>
                                                        <span className="card-text card-text-info">
                                                            <i className="ri-calendar-line mr-1" />
                                                            {actu.created_at.slice(0, 10)}
                                                        </span>
                                                        <span className="card-text card-text-info mx-2">
                                                            <i className="ri-share-line mr-1" />
                                                            <a href={actu.lien} target="_blank" > Lien vers le Site</a>
                                                        </span>
                                                    </div>
                                                    <p className="my-2 card-description short">
                                                        {actu.description.length > maxDescriptionLength ? actu.description.slice(0, maxDescriptionLength) + '...' : actu.description}
                                                    </p>
                                                </div>
                                                <div className="card-footer">
                                                    <div className=' text-center'>
                                                        <button type="button" class="btn btn-info ml-0" onClick={() => showItem(actu)}>
                                                            <i class="ri-eye-line font-size-16"></i>
                                                            Consulter
                                                        </button>
                                                        <button type="button" class="btn btn-warning ml-3" onClick={() => showEditForm(actu)}>
                                                            <i class="ri-pencil-fill font-size-16"></i>
                                                            Modifier
                                                        </button>
                                                        <button type="button" class="btn btn-danger ml-3" onClick={() => showDeleteConfirmation(actu.id)}>
                                                            <i class="ri-delete-bin-line font-size-16"></i>
                                                            Supprimer
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        actuDuJours: state.actuDuJours.actuDuJours,
        loading: state.actuDuJours.loading,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchActuDuJours: () => dispatch(fetchActuDuJours()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActuDuJourPage);