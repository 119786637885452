import axios from 'axios';
import config from '../../config';

export const fetchAnnoncesSuccess = (annonces) => {
  return {
    type: 'FETCH_ANNONCES_SUCCESS',
    payload: annonces
  };
};

export const fetchAnnonces = () => {
  return (dispatch) => {
    axios.get(config.apiBaseUrl + "petites-annonces/all")
      .then((response) => {
        dispatch(fetchAnnoncesSuccess(response.data.returnValue));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const createAnnonces = (newAnnoncesData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "petites-annonces/create";
    const response = await axios.post(url, newAnnoncesData);
    if (response.data.success) {
      const createdAnnonces= response.data.returnValue;
      dispatch({ type: 'CREATE_ANNONCES_SUCCESS', payload: createdAnnonces });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('createAnnonces failed:', error);
    return false;
  }
};

export const updateAnnonces = (id, updatedAnnoncesData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "petites-annonces/update";
    const response = await axios.post(url, updatedAnnoncesData);
    if (response.data.success) {
      const url = config.apiBaseUrl + "petites-annonces/all";
      const response = await axios.get(url);
      let newAnnonces = response.data.returnValue;
      dispatch({ type: 'UPDATE_ANNONCES_SUCCESS', payload: { newAnnonces } });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('updateAnnonces failed:', error);
    return false;
  }
};

export const deleteAnnonces = (id) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "petites-annonces/" + id;
    const response = await axios.delete(url);
    if (response.data.success) {
      dispatch({ type: 'DELETE_ANNONCES_SUCCESS', payload: id });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('deleteAnnonces failed:', error);
    return false;
  }
};