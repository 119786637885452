import { Dna } from 'react-loader-spinner';

const Spinner = () => {
    const styleLoader = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (
        <div className="">
            <Dna
                style={styleLoader}
                visible={true}
                height="100"
                width="100"
                ariaLabel="dna-loading"
                wrapperStyle={styleLoader}
                wrapperClass="dna-wrapper"
            />
        </div>
    )

};

export default Spinner;