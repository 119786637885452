const initialState = {
    channels: [],
    loading: true,
};

export const channelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CHANNELS_SUCCESS':
            return {
                ...state,
                channels: action.payload,
                loading: false,
            };
        
        case 'CREATE_CHANNEL_SUCCESS':
            const createdChannel = action.payload;
            return {
                ...state,
                channels: [createdChannel,...state.channels],
            };
        
        case 'UPDATE_CHANNEL_SUCCESS':
            const { updatedChannels } = action.payload;
            return {
                ...state,
                channels: updatedChannels,
            };

        case 'DELETE_CHANNEL_SUCCESS':
            let newChannels = state.channels.filter(
                (item) => item.id !== action.payload
            );
            return {
                ...state,
                channels: newChannels,
            };
        
        default:
            return state;
    }
};
