// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  height: 60vh; /* Ajustez la hauteur selon vos besoins */
}

.centered-image {
  max-width: 100%; /* Ajustez la taille de l'image si nécessaire */
  max-height: 100%; /* Ajustez la taille de l'image si nécessaire */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,2BAA2B;EACpD,mBAAmB,EAAE,yBAAyB;EAC9C,YAAY,EAAE,yCAAyC;AACzD;;AAEA;EACE,eAAe,EAAE,+CAA+C;EAChE,gBAAgB,EAAE,+CAA+C;AACnE","sourcesContent":[".image-container {\n  display: flex;\n  justify-content: center; /* Centre horizontalement */\n  align-items: center; /* Centre verticalement */\n  height: 60vh; /* Ajustez la hauteur selon vos besoins */\n}\n\n.centered-image {\n  max-width: 100%; /* Ajustez la taille de l'image si nécessaire */\n  max-height: 100%; /* Ajustez la taille de l'image si nécessaire */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
