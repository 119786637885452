/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUsers, createUser, updateUser, deleteUser } from '../../services/actions/usersActions';
import Swal from 'sweetalert2';
import Spinner from '../../components/Spinner';
import { useDispatch } from 'react-redux';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

function EquipePage(props) {

    let { loading, users, fetchUsers } = props;
    const { user, isAuthenticated } = props;
    const [showForm, setShowForm] = useState(false);
    const [showCardList, setShowCardList] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [editState, setEditState] = useState(false);
    const [cardTitle, setCardTitle] = useState('Ajouter');
    const [formloading, setFormLoading] = useState(false);


    const dispatch = useDispatch();

    function showCreateForm() {
        setShowForm(true);
        setShowCardList(false);
    }

    function hideCreateForm() {
        setCardTitle('Ajouter')
        setShowForm(false);
        setShowCardList(true);
        setFormData({});
        setFormErrors({});
        setEditState(false);
    }

    const [formData, setFormData] = useState({
        nom: '',
        email: '',
        password: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    function isEmpty(value) {
        if (value === '')
            return true;
        else
            return false;
    }
    const validateForm = (data) => {
        //console.log({ data });

        const errors = {};
        if (isEmpty(data.nom) & isEmpty(data.email) & isEmpty(data.password)) {
            errors.global = 'Veuillez remplir les champs';
        } else {
            if (isEmpty(data.nom)) {
                errors.nom = 'Le Nom est requis';
            }
            if (isEmpty(data.email.trim())) {
                errors.email = 'L\'Email est requis';
            }

            if (!editState && isEmpty(data.password.trim())) {
                errors.password = 'Le Mot de Passe est requis';
            }
        }

        return errors;
    };
    const handleSubmit = async (e) => {

        e.preventDefault();
        //console.log({ formData })
        const errors = validateForm(formData);
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        setFormErrors({});
        setFormLoading(true);
        //console.log({ formData })
        if (editState) {
            let updatedUserData = {
                id: formData.id,
                nom: formData.nom,
                email: formData.email,
            };
            if (!isEmpty(formData.password.trim())) {
                updatedUserData.password = formData.password;
            }
            //console.log({ updatedUserData })
            const success = await dispatch(updateUser(updatedUserData.id, updatedUserData));
            if (success) {
                dispatch(showSuccessMessage('Mise à jour effectuée avec succès!'));
                hideCreateForm();
            } else {
                dispatch(showErrorMessage('Une erreur s\'est produite lors de la mise à jour.'));
            }
        } else {
            formData.user_id = user.id;

            const success = await dispatch(createUser(formData));
            if (success) {
                dispatch(showSuccessMessage('Utilisateur ajouté avec succès!'));
                hideCreateForm();
            } else {
                dispatch(showErrorMessage('Une erreur s\'est produite lors de l\'ajout.'));
            }
        }
        setFormLoading(false);

    };

    function showEditForm(user) {
        setCardTitle('Modifier')
        setEditState(true);
        formData.id = user.id;
        formData.nom = user.nom;
        formData.email = user.email;
        formData.password = '';
        showCreateForm();
    }

    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deleteUser(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                }
            }
        });
    };

    //let colors = ['info', 'purple', 'info', 'purple',]
    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                {loading ? (
                    <div className="card-body">
                        <Spinner />
                    </div>
                ) :
                    <div className="row">
                        {showForm && (
                            <div className="col-sm-12 col-lg-12">
                                <div className="iq-card ">
                                    <div className="iq-card-header d-flex justify-content-between ">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">{cardTitle}</h4>
                                            {formErrors.global && (
                                                <div className="invalide-feedback">{formErrors.global}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="iq-card-body ">
                                        <form className="">
                                            <div role="group" className="form-group">
                                                <label
                                                    htmlFor="nom"
                                                    className="d-block"

                                                >
                                                    Nom
                                                </label>
                                                <div>
                                                    <input
                                                        id="nom"
                                                        type="text"
                                                        name="nom"
                                                        className={`form-control ${formErrors.nom ? 'is-invalid' : ''}`}
                                                        value={formData.nom}
                                                        onChange={handleInputChange}
                                                        required
                                                    />
                                                    {formErrors.nom && (
                                                        <div className="invalide-feedback">{formErrors.nom}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label htmlFor="email" className="d-block">
                                                    Email
                                                </label>
                                                <div>
                                                    <input
                                                        id="email"
                                                        type="email"
                                                        name="email"
                                                        className={`form-control ${formErrors.email ? 'is-invalid' : ''}`}
                                                        value={formData.email}
                                                        onChange={handleInputChange}

                                                    />
                                                    {formErrors.email && (
                                                        <div className="invalide-feedback">{formErrors.email}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div role="group" className="form-group">
                                                <label htmlFor="password" className="d-block" >
                                                    Mot de Passe:
                                                </label>
                                                <div>
                                                    <input
                                                        id="password"
                                                        type="password"
                                                        name="password"
                                                        className={`form-control ${formErrors.password ? 'is-invalid' : ''}`}
                                                        value={formData.password}
                                                        onChange={handleInputChange}
                                                    />
                                                    {formErrors.password && (
                                                        <div className="invalide-feedback">{formErrors.password}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <button type="submit" className="btn btn-success" onClick={handleSubmit} disabled={formloading}>
                                                {formloading ? 'Envoi en cours...' : 'Envoyer'}
                                            </button>
                                            <button onClick={() => hideCreateForm()} className="btn iq-bg-danger ml-3 btn-none">
                                                Annuler
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="col-lg-12">
                            <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title text-primary">Equipe</h4>
                                    </div>
                                    <div className="iq-card-header-toolbar d-flex align-items-center">

                                        <button className="btn btn-info" onClick={() => showCreateForm()}>
                                            <i className="ri-add-circle-line" />
                                            Ajouter
                                        </button>
                                    </div>
                                </div>
                                <div className="iq-card-body row custom-notes-space">
                                    <div className="col-md-12">
                                        <div className="iq-card ">
                                            <div className="iq-card-body p-0">
                                                <ul className="todo-task-lists m-0 p-0">
                                                    {!loading && users.map((team, index) => (
                                                        <li className="d-flex align-items-center p-3">
                                                            <div className="user-img img-fluid">
                                                                <img
                                                                    src="/images/user.png"
                                                                    alt="story-img"
                                                                    className="rounded-circle avatar-40"
                                                                />
                                                            </div>
                                                            <div className="media-support-info ml-3">
                                                                <h6 className="d-inline-block">
                                                                    {team.nom}
                                                                </h6>
                                                                <span className="badge badge-info ml-3 text-white">
                                                                    {team.email.toLowerCase().trim() === user.email.toLowerCase().trim() ? 'Vous' : ''}
                                                                </span>
                                                                <p className="mb-0">{team.email}</p>
                                                            </div>
                                                            <div className="iq-card-header-toolbar d-flex align-items-center">
                                                                {team.email.toLowerCase().trim() === user.email.toLowerCase().trim() ? '' : <div class="d-flex align-items-center justify-content-center">
                                                                    <a onClick={() => showEditForm(team)} class="avatar-45 text-center ml-3 rounded-circle iq-bg-info font-size-18"><i class="ri-pencil-fill font-size-16"></i></a>
                                                                    <a onClick={() => showDeleteConfirmation(team.id)} class="avatar-45 rounded-circle text-center ml-3 iq-bg-danger font-size-18">
                                                                        <i class="ri-delete-bin-line font-size-16"></i></a>
                                                                </div>}


                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        users: state.users.users,
        loading: state.users.loading,
        user: state.auth.user,
        isAuthenticated: state.auth.isAuthenticated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUsers: () => dispatch(fetchUsers()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipePage);