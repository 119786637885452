const initialState = {
  pubs: [],
  loading: true,
};
export const pubsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PUBS_SUCCESS':
      return {
        ...state,
        pubs: action.payload,
        loading: false,
      };
    
    case 'CREATE_PUB_SUCCESS':
      const createdPub = action.payload;
      return {
        ...state,
        pubs: [createdPub, ...state.pubs],
      };

    case 'UPDATE_PUB_SUCCESS':
      const { newPubs } = action.payload;
      return {
        ...state,
        pubs: newPubs,
      };
    
    case 'DELETE_PUBS_SUCCESS':
      const updatedPubs = state.pubs.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        pubs: updatedPubs,
        loading: false,
      };
  
    default:
      return state;
  }
};