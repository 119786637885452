import axios from 'axios';
import config from '../../config';

export const fetchPubsSuccess = (pub) => {
  return {
    type: 'FETCH_PUBS_SUCCESS',
    payload: pub
  };
};

export const fetchPubs = () => {
  return (dispatch) => {
    axios.get(config.apiBaseUrl + "pubs/all")
      .then((response) => {
        dispatch(fetchPubsSuccess(response.data.returnValue));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const createPub = (newPubData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "pubs/create";
    const response = await axios.post(url, newPubData);
    if (response.data.success) {
      const createdPub = response.data.returnValue;
      dispatch({ type: 'CREATE_PUB_SUCCESS', payload: createdPub });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('createPub failed:', error);
    return false;
  }
};

export const updatePub = (id, updatedPubData) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "pubs/update";
    const response = await axios.post(url, updatedPubData);
    if (response.data.success) {
      const url = config.apiBaseUrl + "pubs/all";
      const response = await axios.get(url);
      let newPubs = response.data.returnValue;
      dispatch({ type: 'UPDATE_PUB_SUCCESS', payload: { newPubs } });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('updatePub failed:', error);
    return false;
  }
};

export const deletePubs = (id) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "pubs/" + id;
    const response = await axios.delete(url);
    if (response.data.success) {
      dispatch({ type: 'DELETE_PUBS_SUCCESS', payload: id });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('deletePubs failed:', error);
    return false;
  }
};