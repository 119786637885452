const initialState = {
  vraixfaux: [],
  loading: true,
};
export const vraixfauxReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_VRAIFAUX_SUCCESS':
      return {
        ...state,
        vraixfaux: action.payload,
        loading: false,
      };
    
    case 'CREATE_VRAIFAUX_SUCCESS':
      const createdVraiFaux = action.payload;
      return {
        ...state,
        vraixfaux: [createdVraiFaux, ...state.vraixfaux],
      };

    case 'UPDATE_VRAIFAUX_SUCCESS':
      const { newVraiFaux} = action.payload;
      return {
        ...state,
        vraixfaux: newVraiFaux,
      };
    
    case 'DELETE_VRAIFAUX_SUCCESS':
      const updatedVraiFaux = state.vraixfaux.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        vraixfaux: updatedVraiFaux,
        loading: false,
      };
  
    default:
      return state;
  }
};