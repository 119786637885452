/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import config from '../../config';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchPubs, createPub, updatePub,deletePubs } from '../../services/actions/pubsActions';
import Spinner from '../../components/Spinner';
import Swal from 'sweetalert2';
import { showSuccessMessage, showErrorMessage } from '../../toastrActions';

function PubsPage(props) {
    const { loading, pubs, fetchPubs } = props;
    const dispatch = useDispatch();

    function showCreateModal() {
        Swal.fire({
            title: 'Ajouter',
            html:
                '<form><div className="form-group"><label htmlFor="titre">Titre de la Pub</label>' +
                '<input id="titre" class="swal2-input col-md-6" placeholder="Titre de la Pub (optionel)" value=""></div>' +
                ' <div className="form-group"><label htmlFor="lien">Lien de l\'image</label>' +
                '<input id="image" class="swal2-input col-md-6" placeholder="Lien de l\'image" value="">'+
                '<div class="form-group">' +
                '<label for="lien">Lien vers le site</label>' +
                '<input id="lien" class="swal2-input col-md-6" placeholder="Lien vers le site" value="" required>' +
                '</div>' +
                '</div></form > ',
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Envoyer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
                cancelButton: 'btn btn-danger bg-danger',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            preConfirm: async () => {
                const titre = Swal.getPopup().querySelector('#titre').value;
                const image = Swal.getPopup().querySelector('#image').value;
                const lien = Swal.getPopup().querySelector('#lien').value;
                let createData = {
                    titre,
                    image
                }
                const success = await dispatch(createPub(createData));
                if (success) 
                    dispatch(showSuccessMessage('Elément ajouté avec succès!'));
                else
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la création de l'élément."));
                
            },
        });
    }


    function showEditModal(id, slug, originalTitle, originalLink, originalLien) {
        Swal.fire({
            title: 'Modifier',
            html:
                '<form><div className="form-group"><label htmlFor="titre">Titre</label>' +
                '<input id="titreEdit" class="swal2-input col-md-6" placeholder="Titre de la Pub (optionel)" value="' + originalTitle + '"></div>' +
                ' <div className="form-group"><label htmlFor="lien">Lien de l\'image</label>' +
                '<input id="imageEdit" class="swal2-input col-md-6" placeholder="Lien de l\'image" value="' + originalLink + '">'+
                '<div class="form-group">' +
                '<label for="lien">Lien vers le site</label>' +
                '<input id="lienEdit" class="swal2-input col-md-6" placeholder="Lien vers le site" value="' + originalLien + '" required>' +
                '</div>' +
                '</div></form > ',
            showClass: {
                popup: 'animate__animated animate__zoomIn', // Define the show animation
            },
            confirmButtonText: 'Envoyer',
            customClass: {
                confirmButton: 'btn btn-success bg-primary',
                cancelButton: 'btn btn-danger bg-danger',
            },
            focusConfirm: false,
            width: '80rem',
            showCancelButton: true,
            cancelButtonText: 'Annuler',
            preConfirm: async () => {
                const updatedTitle = Swal.getPopup().querySelector('#titreEdit').value;
                const updatedLink = Swal.getPopup().querySelector('#imageEdit').value;
                const updatedLien = Swal.getPopup().querySelector('#lienEdit').value;
                let updateData = {
                    slug,
                    titre: updatedTitle,
                    image: updatedLink,
                    lien: updatedLien
                }
                const success = await dispatch(updatePub(id, updateData));
                if (success)
                    dispatch(showSuccessMessage('Mise à jour effectuée avec succès!'));
                else
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la mise à jour de l'élément."));
            },
        });
    }

    const showDeleteConfirmation = (id) => {
        Swal.fire({
            title: 'Etes vous sûr?',
            text: 'Cette action est irreversible',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Oui,Supprimer!',
            cancelButtonText: 'Annuler',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const success = await dispatch(deletePubs(id));
                if (success) {
                    dispatch(showSuccessMessage('Suppression avec succès!'));
                   
                } else {
                    dispatch(showErrorMessage("Une erreur s'est produite lors de la suppression de l'élément."));
                    
                }
            }
        });
    };

    useEffect(() => {
        fetchPubs();
    }, [fetchPubs]);

     return (
         <div id="content-page" className="content-page">
             <div className="container-fluid">
                 {loading ? (
                     <div className="card-body">
                         <Spinner />
                     </div>
                 ) : 
                     <div className="row">
                         <div className="col-lg-12">
                             <div className="iq-card-block iq-card-stretch iq-card-height overflow-hidden iq-card ">
                                 <div className="iq-card-header d-flex justify-content-between">
                                     <div className="iq-header-title">
                                         <h4 className="card-title text-primary">Liste des Pubs</h4>
                                     </div>
                                     <div className="iq-card-header-toolbar d-flex align-items-center">

                                         <button className="btn btn-info" onClick={() => showCreateModal()}>
                                             <i className="ri-add-circle-line" />
                                             Ajouter
                                         </button>
                                     </div>
                                 </div>
                                 <div className="iq-card-body row">
                                     {!loading && pubs.length === 0 ?
                                         (
                                             <div>
                                                 <h3> Aucune pub n'a été trouvée.</h3>
                                             </div>
                                         ) : pubs.map((pub) => (
                                         <div className="col-sm-12 col-lg-6 ">
                                                 <div className="iq-card lefasoTV actus">
                                                 <div className="iq-card-header d-flex justify-content-between">
                                                     <div className="iq-header-title">
                                                             <h4 className="card-title">{pub.titre}</h4>
                                                             <div className='mt-2'>
                                                                 <span class="badge badge-pill iq-bg-warning my-2 text-dark">
                                                                     <i className="ri-calendar-line mr-1" />
                                                                     {pub.created_at.slice(0, 10)}
                                                                 </span>
                                                                 <span class="mx-2 badge badge-pill iq-bg-info my-2 text-dark">
                                                                     <i className="ri-share-line mr-1" />
                                                                     <a href={pub.lien} target="_blank" > Lien vers le site</a>
                                                                 </span>
                                                             </div>
                                                     </div>
                                                 </div>
                                                 <div className="iq-card-body">
                                                     <div className="">
                                                         <img src={pub.image}
                                                             alt="Responsive image"
                                                             className="img-fluid"
                                                         />
                                                     </div>
                                                     <div className='mt-3'>
                                                             <button type="button" class="btn btn-warning ml-0" onClick={() => showEditModal(pub.id, pub.slug, pub.titre, pub.image, pub.lien)}>
                                                             <i class="ri-pencil-fill font-size-16"></i>
                                                             Modifier
                                                         </button>
                                                             <button type="button" class="btn btn-danger ml-3" onClick={() => showDeleteConfirmation(pub.id)}>
                                                             <i class="ri-delete-bin-line font-size-16"></i>
                                                             Supprimer
                                                         </button>
                                                     </div>
                                                 </div>
                                             </div>
                                         </div>
                                     ))}
                                 </div>
                             </div>
                         </div>
                     </div>
                 }                
             </div>
         </div>
     );
}

const mapStateToProps = (state) => {
    return {
        pubs: state.pubs.pubs,
        loading: state.pubs.loading
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPubs: () => dispatch(fetchPubs())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PubsPage);