// toastrActions.js
import { toastr } from 'react-redux-toastr';

export const showSuccessMessage = (message) => {
    return (dispatch) => {
        toastr.success('Succès', message);
    };
};

export const showErrorMessage = (message) => {
    return (dispatch) => {
        toastr.error('Erreur', message);
    };
};
