const initialState = {
  demandepubs: [],
  loading: true,
};
export const demandePubsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DEMANDEPUBS_SUCCESS':
      return {
        ...state,
        demandepubs: action.payload,
        loading: false,
      };
    
    case 'DELETE_DEMANDEPUBS_SUCCESS':
      const updatedDemandes = state.demandepubs.filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        demandepubs: updatedDemandes,
        loading: false,
      };
  
    default:
      return state;
  }
};