/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import LoginPage from "../pages/Auth/LoginPage.js";
import RecoverPwdPage from "../pages/Auth/RecoverPwdPage";
import RegisterPage from "../pages/Auth/RegisterPage";
import DashboardPage from "../pages/Bot/DashboardPage";
import Sidebar from '../components/Sidebar.js';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import FloatNav from '../components/FloatNav.js';
import NewsPage from '../pages/Bot/NewsPage.js';
import NewsByCategoryPage from '../pages/Bot/NewsByCategoryPage.js';
import ChannelsPage from '../pages/Bot/ChannelsPage.js';
import AboutPage from '../pages/Bot/AboutPage.js';
import ContactsPage from '../pages/Bot/ContactsPage.js';
import ChatPage from '../pages/Bot/ChatPage.js';
import ActuDuJourPage from '../pages/Bot/ActuDuJourPage.js';
import NewsletterPage from '../pages/Bot/NewsletterPage.js';
import DemandePubsPage from '../pages/Bot/DemandePubsPage.js';
import PubsPage from '../pages/Bot/PubsPage.js';
import VraiFauxPage from '../pages/Bot/VraiFauxPage.js';
import PetitesAnnonces from '../pages/Bot/PetitesAnnoncesPage.js';
import ProtectedRoute from './ProtectedRoute';
import Dossiers from '../pages/Bot/DossiersPage.js';
import Conseils from '../pages/Bot/ConseilsPage.js';
import CategoriesPage from '../pages/Bot/CategoriesPage.js';
import NotFound from '../pages/Bot/NotFoundPage.js';
import EquipePage from '../pages/Bot/EquipePage.js'
function Routers() {

    const isAuthenticated = sessionStorage.getItem("isAuthenticated");

    return (
        <BrowserRouter>
            {
                !isAuthenticated &&
                <Routes>
                    <Route exact path="/" element={<LoginPage />} />
                    <Route exact path="/auth/login" element={<LoginPage />} />
                    <Route exact path="/auth/recover-password" element={<RecoverPwdPage />} />
                    <Route exact path="/auth/register" element={<RegisterPage />} />
                </Routes>
            }
            {
                isAuthenticated &&
                <div className="wrapper">
                    <Sidebar />
                    <Header />
                        <Routes>
                            <Route path="/bot" element={<ProtectedRoute />}>
                                <Route path="/bot/dashboard" element={<DashboardPage />} />
                            </Route>
                            <Route path="/bot" element={<DashboardPage />} />
                            <Route path="/" element={<DashboardPage />} />
                            {/* <Route
                                path="/bot/dashboard"
                                element={
                                    <RequireAuth redirectTo="/auth/login">
                                        <DashboardPage />
                                    </RequireAuth>
                                }
                            /> */}
                            {/* <Route path="/bot/dashboard" element={<ProtectedRoute Component={DashboardPage} />} /> */}
                            {/* <ProtectedRoute>
                                <Route path="/bot/dashboard" element={<DashboardPage />} />
                                
                            </ProtectedRoute> */}
                            
                            <Route exact path="/bot/actu-du-jour" element={<ActuDuJourPage />} />
                            <Route exact path="/bot/actualites" element={<NewsPage />} />
                            <Route exact path="/bot/news/category/:code" element={<NewsByCategoryPage />} />
                            <Route exact path="/bot/tv" element={<ChannelsPage />} />
                            <Route exact path="/bot/about" element={<AboutPage />} />
                            <Route exact path="/bot/contacts" element={<ContactsPage />} />
                            <Route exact path="/bot/chat" element={<ChatPage />} />
                            <Route exact path="/bot/newsletter" element={<NewsletterPage />} />
                            <Route exact path="/bot/pubs" element={<PubsPage />} />
                            <Route exact path="/bot/demande-de-pub" element={<DemandePubsPage />} />
                            <Route exact path="/bot/vrai-ou-faux" element={<VraiFauxPage />} />
                            <Route exact path="/bot/petites-annonces" element={<PetitesAnnonces />} />
                            <Route exact path="/bot/dossiers" element={<Dossiers />} />
                            <Route exact path="/bot/conseils-de-ministres" element={<Conseils />} />
                            <Route exact path="/bot/categories" element={<CategoriesPage />} />
                            <Route exact path="/bot/equipe" element={<EquipePage />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    <Footer />
                    <FloatNav />
                </div>
            }
        </BrowserRouter >
    );
}

export default Routers;