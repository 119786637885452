const initialState = {
    news: [],
    loading: true,
  };
  
  export const newsReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_NEWS_SUCCESS':
        return {
          ...state,
          news: action.payload,
          loading: false,
        };
      
      case 'CREATE_NEWS_SUCCESS':
        const createNews = action.payload;
        return {
          ...state,
          news: [createNews, ...state.news],
        };

      case 'UPDATE_NEWS_SUCCESS':
        const { updatedNews } = action.payload;
          return {
            ...state,
            news: updatedNews,
          };

      case 'DELETE_NEWS_SUCCESS':
        let newData = state.news.filter(
            (item) => item.id !== action.payload
          );
          return {
            ...state,
            news: newData,
          };
      
      default:
        return state;
    }
  };
  