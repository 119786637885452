/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function RegisterPage(props) {

    return (
        <div>
            <section className="sign-in-">
                <div className="container mt-5 p-0 bg-white">
                    <div className="row no-gutters">
                        <div className="col-sm-6 align-self-center">
                            <div className="sign-in-from">
                                <h1 className="mb-0">Création De Compte</h1>
                                <p>Enter your email address and password to access admin panel.</p>
                                <form className="mt-4">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Nom & Prénom</label>
                                        <input type="email" className="form-control mb-0" id="exampleInputEmail1" placeholder="Identité" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail2">Email</label>
                                        <input type="email" className="form-control mb-0" id="exampleInputEmail2" placeholder="Email" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Mot De Passe</label>
                                        <input type="password" className="form-control mb-0" id="exampleInputPassword1" placeholder="Mot De Passe" />
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <button type="submit" className="btn btn-primary float-right">S'enregister</button>
                                    </div>
                                    <div className="sign-info">
                                        <span className="dark-color d-inline-block line-height-2">Déjà Un Compte ? <a href="/auth/login">Connectez-Vous Ici</a></span>
                                        <ul className="iq-social-media">
                                            <li><a href="#"><i className="ri-facebook-box-line" /></a></li>
                                            <li><a href="#"><i className="ri-twitter-line" /></a></li>
                                            <li><a href="#"><i className="ri-instagram-line" /></a></li>
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-6 text-center">
                            <div className="sign-in-detail text-white">
                                <div className="slick-slider11">
                                    <div className="item">
                                        <img src="/images/login/1.png" className="img-fluid mb-4" alt="logo" />
                                        <h4 className="mb-1 text-white">Gérez votre chatbot WhatsApp</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    );
}