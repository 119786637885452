import axios from 'axios';
import config from '../../config';

export const fetchConseilsSuccess = (conseils) => {
    return {
        type: 'FETCH_CONSEILS_SUCCESS',
        payload: conseils
    };
};

export const fetchConseils = () => {
    return (dispatch) => {
        axios.get(config.apiBaseUrl + "conseilministres/all")
            .then((response) => {
                dispatch(fetchConseilsSuccess(response.data.returnValue));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};
export const createConseil = (newConseilData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "conseilministres/create";
        const response = await axios.post(url, newConseilData);
        if (response.data.success) {
            const createdConseil= response.data.returnValue;
            dispatch({ type: 'CREATE_CONSEILS_SUCCESS', payload: createdConseil });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('createConseil failed:', error);
        return false;
    }
};
    
export const updateConseil = (id, updatedConseilsData) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "conseilministres/update";
        const response = await axios.post(url, updatedConseilsData);
        if (response.data.success) {
            const url = config.apiBaseUrl + "conseilministres/all";
            const response = await axios.get(url);
            let updatedConseil = response.data.returnValue;
            dispatch({ type: 'UPDATE_CONSEILS_SUCCESS', payload: { updatedConseil } });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('updateConseil failed:', error);
        return false;
    }
};


export const deleteConseil = (id) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "conseilministres/" + id;
        const response = await axios.delete(url);
        if (response.data.success) {
            dispatch({ type: 'DELETE_CONSEILS_SUCCESS', payload: id });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('deleteConseil failed:', error);
        return false;
    }
};
