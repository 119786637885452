/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export default function RecoverPwdPage(props) {

    return (
        <div>
            <section className="sign-in-page">
                <div className="container bg-white mt-5 p-0">
                    <div className="row no-gutters">
                        <div className="col-sm-6 align-self-center">
                            <div className="sign-in-from">
                                <h1 className="mb-0">Réinitialisation Du Mot De Passe</h1>
                                <p>Veuillez renseigner votre adresse e-mail et nous vous enverrons un e-mail avec des instructions pour réinitialiser votre mot de passe.</p>
                                <form className="mt-4">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Email</label>
                                        <input type="email" className="form-control mb-0" id="exampleInputEmail1" placeholder="Email" />
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <button type="submit" className="btn btn-primary float-right">Réinitialisation</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-sm-6 text-center">
                            <div className="sign-in-detail text-white">
                                <div className="slick-slider11" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items={1} data-items-laptop={1} data-items-tab={1} data-items-mobile={1} data-items-mobile-sm={1} data-margin={0}>
                                    <div className="item">
                                        <img src="/images/login/1.png" className="img-fluid mb-4" alt="logo" />
                                        <h4 className="mb-1 text-white">Gérez votre chatbot WhatsApp</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}