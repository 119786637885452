const initialState = {
    customFieldId: null,
    subscribers: [],
};

export const contactsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CUSTOM_FIELD_ID':
            return { ...state, customFieldId: action.payload };
        case 'SET_SUBSCRIBERS':
            return { ...state, subscribers: action.payload };
        default:
            return state;
    }
};

