import axios from 'axios';
import config from '../../config';

export const fetchDemandePubsSuccess = (demandepub) => {
  return {
    type: 'FETCH_DEMANDEPUBS_SUCCESS',
    payload: demandepub
  };
};

export const deleteDemandePubsSuccess = (id) => {
  return {
    type: 'DELETE_DEMANDEPUBS_SUCCESS',
    payload: id
  };
};
export const fetchDemandePubs = () => {
  return (dispatch) => {
    axios.get(config.apiBaseUrl +"demandepub/all")
      .then((response) => {
        dispatch(fetchDemandePubsSuccess(response.data.returnValue));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};


export const deleteDemandePubs = (id) => async (dispatch) => {
  try {
    const url = config.apiBaseUrl + "demandepub/" + id;
    const response = await axios.delete(url);
    if (response.data.success) {
      dispatch({ type: 'DELETE_DEMANDEPUBS_SUCCESS', payload: id });
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('deleteDemandePubs failed:', error);
    return false;
  }
};