/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomFieldId, getNewSubscribers } from '../../services/actions/contactsActions';

function ChatPage(props) {

    const dispatch = useDispatch();
    const subscribers = useSelector((state) => state.contacts.subscribers);

    useEffect(() => {
        const fetchData = async () => {
            const id = await dispatch(getCustomFieldId());
            if (id) {
                dispatch(getNewSubscribers(id));
            }
        };
        fetchData();
    }, [dispatch]);

    return (
        <div id="content-page" className="content-page">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-body chat-page p-0">
                                <div className="chat-data-block">
                                    <div className="row">
                                        <div className="col-lg-3 chat-data-left scroller">
                                            <div className="chat-search pt-3 pl-3">
                                                <div className="chat-searchbar mt-4">
                                                    <div className="form-group chat-search-data m-0">
                                                        <input type="text" className="form-control round" id="chat-search" placeholder="Recherche" />
                                                        <i className="ri-search-line" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="chat-sidebar-channel scroller mt-4 pl-3">
                                                <h5 className>Contacts</h5>
                                                <ul className="iq-chat-ui nav flex-column nav-pills">
                                                    {subscribers.map((subscriber) => (
                                                        <li>
                                                            <a data-toggle="pill" href="#chatbox1">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="avatar mr-3">
                                                                        <div className="d-flex">
                                                                            <span className="avatar-45 text-center ml-3 rounded-circle iq-bg-info font-size-18">{subscriber.name.charAt(0).toUpperCase()}</span>
                                                                        </div>
                                                                        <span className="avatar-status"><i className="ri-checkbox-blank-circle-fill text-success" /></span>
                                                                    </div>
                                                                    <div className="chat-sidebar-name">
                                                                        <h6 className="mb-0">{subscriber.name}</h6>
                                                                        <span>{subscriber.whatsapp_phone}</span>
                                                                    </div>
                                                                    <div className="chat-meta float-right text-center mt-2">
                                                                        <div className="chat-msg-counter bg-primary text-white">20</div>
                                                                        <span className="text-nowrap">05 min</span>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-lg-9 chat-data p-0 chat-data-right">
                                            <div className="tab-content">
                                                <div>
                                                    <div className="chat-content scroller">
                                                        <div className="chat">
                                                            <div className="chat-user">
                                                                <a className="avatar m-0">
                                                                    <img src="/images/logo.png" alt="avatar" className="avatar-35 " />
                                                                </a>
                                                                <span className="chat-time mt-1">6:45</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Salut</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chat chat-left">
                                                            <div className="chat-user">
                                                                <a className="avatar m-0">
                                                                </a>
                                                                <span className="chat-time mt-1">6:48</span>
                                                            </div>
                                                            <div className="chat-detail">
                                                                <div className="chat-message">
                                                                    <p>Salut</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chat-footer p-3 bg-white">
                                                        <form className="d-flex align-items-center" action="javascript:void(0);">
                                                            <div className="chat-attagement d-flex">
                                                                <a href="javascript:void();"><i className="fa fa-smile-o pr-3" aria-hidden="true" /></a>
                                                                <a href="javascript:void();"><i className="fa fa-paperclip pr-3" aria-hidden="true" /></a>
                                                            </div>
                                                            <textarea type="text" className="form-control mr-3" placeholder="Saisir le message" />
                                                            <button type="submit" className="btn btn-primary d-flex align-items-center p-2"><i className="fa fa-paper-plane-o" aria-hidden="true" /><span className="d-none d-lg-block ml-1">Envoyer</span></button>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatPage;