import axios from 'axios';
import config from '../../config';

export const fetchCategoriesSuccess = (news) => {
    return {
        type: 'FETCH_CATEGORIES_SUCCESS',
        payload: news
    };
};

export const fetchCategories = () => {
    return (dispatch) => {
        axios.get(config.apiBaseUrl + "categories/all")
            .then((response) => {
                //console.log(response.data.returnValue)
                dispatch(fetchCategoriesSuccess(response.data.returnValue));
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const fetchNewsByCategory = (code) => async (dispatch) => {
    try {
        const url = config.apiBaseUrl + "categories/" + code + "/actualites/last/10000";
        const response = await axios.get(url);
        if (response.data.success) {
            const newsByCat = response.data.returnValue;
            dispatch({ type: 'FETCH_NEWS_BY_CATEGORY_SUCCESS', payload: newsByCat });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('fetchNewsByCategory failed:', error);
        return false;
    }
};